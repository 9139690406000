import { NgModule, Provider, Type, inject } from '@angular/core'
import {
	Routes,
	Route,
	Router,
	CanMatchFn,
	UrlSegment,
	CanActivateFn,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
} from '@angular/router'
import { AuthService } from '@eliq/feature/auth' //authguard and loginguard exists here too
import { TicketsComponent } from '@eliq/feature/tickets'
import { ChatComponent } from '@eliq/feature/chat/components/chat/chat.component'
import { environment } from 'src/environments/environment'
import { HomeComponent } from './views/home/home/home.component'
import { LoginComponent } from './views/login/login/login.component'
import { EnvironmentService } from '@eliq/data-access'
import { FlowService } from '@eliq/feature/integrationless/flow/flow-service/flow.service'
import { NotFoundComponent } from '@eliq/ui/layout/404/404.component'
import { PageNotFoundComponent } from './views/404/page-not-found.component'
import { SessionExpiredComponent } from './views/login/session-expired/session-expired.component'
import { DummyComponent } from './dummyComponent.component'

const INTEGRATIONLESS_CONFIG = {
	enabled: (environment as any)?.integrationless?.enabled === true || false,
	useWelcomePage: (environment as any)?.integrationless?.useWelcomePage !== false,
	usePreLoginPage: (environment as any)?.integrationless?.usePreLoginPage !== false
};

const IS_INTEGRATIONLESS = INTEGRATIONLESS_CONFIG.enabled;

const ENABLE_PAYPOINT_TICKETS = (environment as any)?.enablePaypointTickets || false
const DISABLED_ROUTES = (environment as any)?.disabledRoutes || []
const REPLACED_ROUTES = (environment as any)?.replaceRoute || []

const AuthGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot,
): boolean => {
	const authService = inject(AuthService)
	if (authService.isLoggedIn()) {
		if (!IS_INTEGRATIONLESS) {
			return true
		} //else // TODO this flowService here was never used so I uncommented this out but this feels weird, should look at this more closely. Everything works so not the priority right now.
			//const flowService = inject(FlowService)
		//}
		return true
	}
	if (DISABLED_ROUTES.includes('login')) {
		return false
	}
	inject(Router).navigate(!IS_INTEGRATIONLESS ? ['/login'] : ['/'])

	return false
}

const ExternalRedirectResolver: CanActivateFn = (route, state) : boolean => {
	const externalUrl = route.data?.['externalUrl'];
	if (externalUrl) {
		window.location.href = externalUrl;
	}
	return false;
}

const NotLoggedInGuard: CanActivateFn = (
	_route: ActivatedRouteSnapshot,
	_state: RouterStateSnapshot,
): boolean => {
	const authService = inject(AuthService)

	if (!authService.isLoggedIn()) {
		return true
	}

	var startPage = ['/home']
	if (INTEGRATIONLESS_CONFIG.enabled && INTEGRATIONLESS_CONFIG.useWelcomePage){
		startPage = ["/welcome"]
	}

	inject(Router).navigate(startPage)
	return false
}

export const routes: Routes = [
	{
		path: 'connect-meter',
		loadComponent: () =>
			import('./views/connect-meter/connect-meter.component').then(
				(m) => m.ConnectMeterComponent,
			),
	},
	{
		path: 'webview-agreements',
		loadChildren: () =>
			import('./views/webview-agreements/webview-agreements.module').then(
				(m) => m.WebviewAgreementsModule,
			),
		canActivate: [],
	},
	{
		path: 'login/:ticketId',
		loadComponent: () =>
			import('./views/login/autologin/autologin.component').then(
				(m) => m.AutoLoginComponent,
			),
		canActivate: [],
	}, //canActivate: [/*LoginGuard*/] },
	{
		path: 'home',
		component: HomeComponent,
		canActivate: [AuthGuard],
	},
	{
		path: 'pv',
		loadComponent: () =>
			import('./views/pv/pv/pv.component').then((m) => m.PVComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'embed-with-ticket',
		loadComponent: () =>
			import(
				'./views/embed-with-ticket/embed-with-ticket/embed-with-ticket.component'
			).then((m) => m.EmbedWithTicketComponent),
		canActivate: [],
	},
	{ path: 'chat', component: ChatComponent },
	{
		path: 'insights',
		loadComponent: () =>
			import('./views/insights/insights/insights.component').then(
				(m) => m.InsightsComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'bills',
		loadComponent: () =>
			import('./views/bills/bills/bills.component').then(
				(m) => m.BillsComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'notifications',
		loadComponent: () =>
			import(
				'./views/notifications/notifications/notifications.component'
			).then((m) => m.NotificationsComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'account',
		loadComponent: () =>
			import('./views/account/account/account.component').then(
				(m) => m.AccountComponent,
			),
		canActivate: [AuthGuard],
	},
	{ path: 'tickets', component: TicketsComponent },
	{
		path: 'energy-advisor',
		loadComponent: () =>
			IS_INTEGRATIONLESS ? import(
				'@eliq/feature/integrationless/il-energy-advisor-container/il-energy-advisor-container.component'
			).then((m) => m.ILEnergyAdvisorContainerComponent) : import(
				'@eliq/feature/energy-advisor/components/energy-advisor-container/energy-advisor-container.component'
			).then((m) => m.EnergyAdvisorContainerComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'energy-advisor/:adviceId',
		loadComponent: () =>
			IS_INTEGRATIONLESS ? import(
				'@eliq/feature/integrationless/il-energy-advisor-container/il-energy-advisor-container.component'
			).then((m) => m.ILEnergyAdvisorContainerComponent) : import(
				'@eliq/feature/energy-advisor/components/energy-advisor-container/energy-advisor-container.component'
			).then((m) => m.EnergyAdvisorContainerComponent),
		canActivate: [AuthGuard],
	},
	{
		path: 'renewal',
		loadComponent: () =>
			import('./views/renewal/renewal/renewal.component').then(
				(m) => m.RenewalComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'budget',
		loadComponent: () =>
			import('./views/budget/budget/budget.component').then(
				(m) => m.BudgetComponent,
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'electric-vehicle',
		loadComponent: () =>
			import(
				'./views/electric-vehicle/electric-vehicle/electric-vehicle.component'
			).then((m) => m.ElectricVehicleComponent),
		canActivate: [AuthGuard],
	},
]

if (!DISABLED_ROUTES.includes('login')) {
	routes.push({
		path: 'login',
		component: LoginComponent,
		canActivate: [NotLoggedInGuard],
	})
} else {
	routes.push({
		path: 'login',
		component: SessionExpiredComponent,
		canActivate: [NotLoggedInGuard],
	})
}

if (REPLACED_ROUTES){
	for (let index = 0; index < REPLACED_ROUTES.length; index++) {
		const newRouteConfig = REPLACED_ROUTES[index];
		let newRoute;
		if (newRouteConfig){
			if (newRouteConfig.isExternal === true){
				newRoute = {
					path: newRouteConfig.currentRoute,
					component: DummyComponent,
					canActivate: [ExternalRedirectResolver],
					data: { externalUrl: newRouteConfig.redirection }
				};
			}
			else {
				newRoute ={
					path: newRouteConfig.currentRoute,
					redirectTo: newRouteConfig.redirection
				};
			}

			const routeIndex = routes.findIndex(route => route.path === newRouteConfig.currentRoute);
			if (routeIndex)
				routes[routeIndex] = newRoute;
		}
	}
}

export const dynamicRoutes: Routes = (() => {
	let result: Routes;

	// Login
	let loginRoute;
	if (INTEGRATIONLESS_CONFIG.enabled && INTEGRATIONLESS_CONFIG.usePreLoginPage) {
		loginRoute = {
			canActivate: [NotLoggedInGuard],
			loadComponent: () =>
				import(
					'@eliq/feature/integrationless/integration-less-home/il-home/il-home.component'
				).then((m) => m.ILHomeComponent),
	  	};
	}
	else {
		loginRoute = { redirectTo: '/login' };
	}

	// Paypoint
	let payPointRoute;
	if (ENABLE_PAYPOINT_TICKETS){
		payPointRoute = [
			{
				path: 'paypoint-ticket',
				loadComponent: () =>
					import(
						'@eliq/feature/paypoint-ticket/paypoint-ticket.component'
					).then((m) => m.PaypointTicketComponent),
				canActivate: [],
			}
		];
	}
	else {
		payPointRoute = [];
	}

	// Integration-less
	let integrationlessRoutes;
	if (INTEGRATIONLESS_CONFIG.enabled){
		integrationlessRoutes = [
			{
				path: 'flow',
				loadComponent: () =>
					import(
						'@eliq/feature/integrationless/flow/flow/flow.component'
					).then((m) => m.FlowComponent),
				canActivate: [AuthGuard],
			},
			{
				path: 'flow/:state',
				loadComponent: () =>
					import(
						'@eliq/feature/integrationless/flow/flow/flow.component'
					).then((m) => m.FlowComponent),
				canActivate: [AuthGuard],
			},
			{
				path: 'flow/homeprofile/:step',
				loadComponent: () =>
					import(
						'@eliq/feature/integrationless/flow/flow/flow.component'
					).then((m) => m.FlowComponent),
				canActivate: [AuthGuard],
			}
	  	];

		if (INTEGRATIONLESS_CONFIG.useWelcomePage){
			integrationlessRoutes.push({
				path: 'welcome',
				loadComponent: () =>
					import(
						'@eliq/feature/integrationless/il-welcome/il-welcome.component'
					).then((m) => m.ILWelcomeComponent),
				canActivate: [AuthGuard],
			});
		}
	}
	else {
		integrationlessRoutes = [];
	}

	return [
		{
			path: '',
			pathMatch: 'full',
			...loginRoute,
		},
		...payPointRoute,
		...integrationlessRoutes,
		...routes,
		{
			path: '**',
			component: NotFoundComponent,
		},
	]
})()
