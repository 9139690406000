<div *ngIf="outputs">
	<div *ngIf="outputs.length===2; else odd">
		<table class="top">
			<tbody>
				<tr>
					<td *ngFor="let data of outputs">
						<div class="evenname secondary-p3" translate>
							{{'common.'+data.name}}
						</div>
						<div>
							<span class="kr primary-p1">
								{{data.leftValue}}
							</span>
							<span class="consumption-card-kwh">
								<span class="kwh primary-p1">{{data.rightValue}}</span>
							</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<p
		*ngIf="hasAdditionalPopperInfo && enableAdditionalPopperInfo"
		[translate]="'home_consumption_summary_month.additional_popover_info'"
	></p>
</div>

<ng-template #odd>
	<div *ngFor="let data of outputs">
		<div class="outputrow">
			<span class="oddname secondary-p3" translate>
				{{'common.'+data.name}}
			</span>
			<span style="position: absolute; right: 8px;">
				<span class="kr primary-p1">
					{{data.leftValue}}
				</span>
				<span class="consumption-card-kwh">
					<span class="kwh primary-p1">{{data.rightValue}}</span>
				</span>
			</span>
		</div>
	</div>
</ng-template>
