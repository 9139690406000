<eliq-consumption
	*ngIf="isVisible"
	[units]="units"
	[date]="date"
	[toDate]="periodToDate"
	[fuels]="fuels"
	[consumptionCost]="consumptionCost"
	[consumptionEnergy]="consumptionEnergy"
	[forecastCost]="forecastCost"
	[forecastEnergy]="forecastEnergy"
	[forecastCostNextMonth]="forecastCostNextMonth"
	[forecastEnergyNextMonth]="forecastEnergyNextMonth"
	[previousPeriodConsumption]="previousPeriodConsumption"
	[similarHomesConsumption]="similarHomesConsumption"
	[isEstimated]="isEstimated"
></eliq-consumption>
