import { Inject, Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, filter, map, shareReplay, take } from 'rxjs/operators'
import {
	HttpClient,
	HttpEvent,
	HttpEventType,
	HttpRequest,
	HttpResponse,
} from '@angular/common/http'
import { ConfigHeaderItem } from '../models/config-header-item.model'
import { ConfigFile } from '../models/config-file.model'
import { AccountCategory } from '../models/config-account-structure.model'
import { ConfigContact } from '../models/config-contact.model'
import { ConfigInsights } from '../models/config-insights.model'
import { ConfigLanguage } from '../models/config-language.model'
import { ConfigReferAFriend } from '../models/config-refer-a-friend.model'
import { ConfigBills } from '../models/config-bills.model'
import { ConfigNotifications } from '../models/config-notifications.model'
import { ConfigLogin } from '../models/config-login.model'
import { ConfigBetterWorld } from '../models/config-better-world.model'
import { ConfigAnnualCard } from '../models/config-annual-card.model'
import { ConfigMeterConnection } from '../models/config-meter-connection'

@Injectable({
	providedIn: 'root',
})
export class JsonGetterService {
	private assetsBaseUrl: string
	private config$: Observable<ConfigFile>

	constructor(
		private http: HttpClient,
		@Inject('ASSETS_BASE_URL') assetsBaseUrl: string,
	) {
		this.assetsBaseUrl = assetsBaseUrl
		this.config$ = this.loadConfig().pipe(shareReplay(1))
	}

/* 	getFooterInfo(): Observable<{
		link: string
		text: string
		linkText: string
		policyLink: string
	}> {
		return this.getConfig().pipe(
			map((config) => ({
				...{ link: 'eliq.com', text: 'Eliq AB', linkText: 'eliq.com' },
				...config.footer,
			})),
		)
	} */
	getFooterInfo(): Observable<{
		link: string
		text: string
		linkText: string
		policyLink: string
	}> {
		return this.getConfig().pipe(
			map((config) => ({
				...{ link: 'eliq.com', text: 'Eliq AB', linkText: 'eliq.com', policyLink: 'eliq.com/privacy/'},
				...config["footer"] ?? {},
			})),
		)
	}
	getAccountStructureConfig(): Observable<AccountCategory[]> {
		return this.getConfig().pipe(
			map((config) => config.account_structure.categories),
		)
	}

	getTermsAndConditionsLink(): Observable<string> {
		return this.getConfig().pipe(
			map((config) => config.terms_and_conditions?.url),
		)
	}

	getPrivacyPolicyLink(): Observable<string> {
		return this.getConfig().pipe(map((config) => config.privacy_policy?.url))
	}

	getHeaderItems(): Observable<ConfigHeaderItem[]> {
		return this.getConfig().pipe(map((config) => config.header_items))
	}

	getLanguages(): Observable<ConfigLanguage[]> {
		return this.getConfig().pipe(map((config) => config.languages))
	}

	getContact(): Observable<ConfigContact> {
		return this.getConfig().pipe(map((config) => {
			var emails: string[] = [];
			if (Array.isArray(config.contact.email)){
				emails = config.contact.email
			} else if (config.contact.email) {
				emails = [ config.contact.email ]
			}

			var result: ConfigContact = {
				email: emails,
				phone: config.contact.phone,
				social_medias: config.contact.social_medias
			}

			return result
		}))
	}

	getInsightsConfig(): Observable<ConfigInsights> {
		return this.getConfig().pipe(map((config) => config.insights))
	}

	getReferAFriend(): Observable<ConfigReferAFriend> {
		return this.getConfig().pipe(map((config) => config.refer_a_friend))
	}

	getAnnualCard(): Observable<ConfigAnnualCard> {
		return this.getConfig().pipe(map((config) => config.annual_card))
	}

	getBetterWorldConfig(): Observable<ConfigBetterWorld> {
		return this.getConfig().pipe(map((config) => config.better_world))
	}

	getNotificationConfig(): Observable<ConfigNotifications> {
		return this.getConfig().pipe(map((config) => config.notifications))
	}

	getBillsConfig(): Observable<ConfigBills> {
		return this.getConfig().pipe(map((config) => config.bills))
	}

	getLoginConfig(): Observable<ConfigLogin> {
		return this.getConfig().pipe(map((config) => config.login))
	}

	getClientName(): Observable<string | undefined> {
		return this.getConfig('general').pipe(
			map((config) => config.client_name),
			catchError(() => of(undefined)),
		)
	}

	getHomeProfileConfig() {
		return this.getConfig('home_profile').pipe(
			map((config) =>
				Object.assign(
					{
						enableYearlyEstimates: false,
						only_is_required_properties: false,
					},
					config,
				),
			),
		) as Observable<{
			enableYearlyEstimates: boolean
			only_is_required_properties: boolean
		}>
	}

	/**
	 * Check if client is handling terms itself (in this case we don't need to ask users to accept Terms of Service or Privacy Policy)
	 * @returns Observable<boolean>
	 */
	isClientHandlingTerms(): Observable<boolean> {
		return this.getConfig().pipe(
			map((config) => config.terms_and_conditions?.is_client_handling_terms),
		)
	}

	getMeterConnectionConfig(): Observable<ConfigMeterConnection> {
		return this.getConfig('meter_connection')
	}

	/**
	 *
	 * @param configKey the key of the sub config object you want. set as undefined to get entire config.options object
	 * @returns
	 */
	getConfig(configKey?: string) {
		return this.config$.pipe(
			map((config) => {
				return configKey ? (config.options as any)[configKey] : config.options
			}),
		)
	}

	private removeCommentsFromJson(json: string): string {
		const noComments = json.replace(
			/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g,
			(m, g) => (g ? '' : m),
		)

		return noComments
	}

	//unused this whole thing
	private loadConfig(): Observable<ConfigFile> {
		const bustCache = false

		const jsoncReq = this.http.request<string>(
			new HttpRequest(
				'GET',
				`${this.assetsBaseUrl}assets/config/config.jsonc${
					bustCache ? '?_cache_buster=' + new Date().getTime() : ''
				}`,
				{
					responseType: 'text',
					reportProgress: false,
				},
			),
		)

		const jsonReq = jsoncReq.pipe(
			map((event: HttpEvent<string>) => {
				let json = event.type === HttpEventType.Response ? event.body : ''
				if (!json) {
					return undefined
				}
				json = this.removeCommentsFromJson(json)
				const config = JSON.parse(json) as ConfigFile
				return config
			}),
			filter((config) => !!config),
			map((config) => config as ConfigFile),
			take(1),
		)

		return jsonReq
	}
}
