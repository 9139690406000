<eliq-card class="eliq-default-card-style" style="padding: 24px">
	<h6
		class="primary-h6 header"
		style="margin: 0; text-align: center;"
		translate
	>
		home_profile_prompt.set_up_home_profile
	</h6>
	<p style="text-align: center;" translate>
		home_profile_prompt.we_need_information_about_home
	</p>
	<eliq-basic-button (click)="addProfileClick.emit()" [useEntireWidth]="true">
		{{'insights_comparison_similar_homes.add_home_profile' | translate}}
	</eliq-basic-button>
</eliq-card>
