import { DecimalPipe, NgIf, LowerCasePipe } from '@angular/common'
import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	inject,
} from '@angular/core'
import {
	ConsumptionHelperService,
	PeriodType,
	ResolutionType,
} from '@eliq/core'
import { APIData } from '@eliq/core/models/src/api-models/api-data.model'
import { ChartHelperService } from '@eliq/core/highcharts-utils'
import {
	ColorServiceService,
	DateTranslatorService,
	EliqCurrencyPipe,
	EliqNumberPipe,
	Period,
} from '@eliq/core'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { EliqThemeService } from '@eliq/theme'

@Component({
	selector: 'eliq-homechart',
	templateUrl: './homechart.component.html',
	styleUrls: ['./homechart.component.scss'],
	standalone: true,
	imports: [NgIf, LowerCasePipe, TranslateModule],
})
export class HomechartComponent implements OnInit, OnChanges {
	@Input() unit!: 'cost' | 'energy'
	@Input() consumption!: Map<string, number[]>
	@Input() production!: APIData
	@Input() forecast!: Map<string, number[]>
	@Input() importData!: APIData
	@Input() exportData!: APIData
	@Input() period!: Period
	@Input() resolutionType!: ResolutionType
	//@Input() fontFamily!: string
	@Input() categoryType!: string
	@Input() temperatures!: number[]

	public themeService = inject(EliqThemeService)
	public errorMessage = ''

	private Highcharts: any
	private scrollablePlotAreaMinWidth = 650
	private setupDone = false
	private containerElement!: HTMLElement
	private isBig!: boolean

	public month = ''
	public year!: number
	public total!: number
	public isTotalAvailable = false
	myChart: any

	public headerKey = ''
	public headerKeyData: any
	public headerValue = ''
	public headerValueKey = ''
	constructor(
		private translator: TranslateService,
		private dateTranslator: DateTranslatorService,
		private consumptionHelperService: ConsumptionHelperService,
		private chartHelper: ChartHelperService,
		private currencyPipe: EliqCurrencyPipe,
		private decPipe: DecimalPipe,
		private eliqNumberPipe: EliqNumberPipe,
		private element: ElementRef,
	) {}

	ngOnInit() {
		this.setup()
		this.setupDone = true
	}

	ngOnChanges() {
		if (this.setupDone) {
			this.setup()
		}
	}

	private getChartContainerElement() {
		return this.element.nativeElement.firstChild.lastChild.lastChild
	}

	/*async ngAfterViewInit() {
    // get the container element for the chart for later use in onResize logic
    if (!this.Highcharts) await loadHighcharts.then(_ => {
      this.containerElement = document.getElementById("chart-container");
      this.onResize(undefined);
    });
  }*/

	onResize(event: any) {
		const width = this.element.nativeElement.firstChild.offsetWidth
		if (this.isBig === undefined) {
			// not set up yet
			this.reflowChart(width > this.scrollablePlotAreaMinWidth)
			this.isBig = width > this.scrollablePlotAreaMinWidth
		} else if (this.isBig && width <= this.scrollablePlotAreaMinWidth) {
			// is big, make small
			this.isBig = false
			this.reflowChart(false)
		} else if (!this.isBig && width > this.scrollablePlotAreaMinWidth) {
			// is small, make big
			this.isBig = true
			this.reflowChart(true)
		}
	}

	private reflowChart(makeBig: boolean) {
		if (makeBig) {
			this.myChart?.options?.chart?.margin?.length &&
				(this.myChart.options.chart.margin[2] = 25)
		} else {
			this.myChart?.options?.chart?.margin?.length &&
				(this.myChart.options.chart.margin[2] = 45)
		}

		this.myChart.isDirtyBox = true
		this.myChart.redraw()
	}

	// Creates a series for production, import or export since these are all APIData
	private createProductionLikeSeries(
		apiDataSource: APIData,
		fuelName = 'common.production',
		dataStreamName = 'common.consumption',
		options?: any,
	) {
		const default_series = {
			type: 'column',
			data: apiDataSource.data.map((point, index) => {
				return {
					y: point,
					color: point === 0 ? 'rgba(0,0,0,0)' : undefined,
					borderColor: point === 0 ? 'rgba(0,0,0,0)' : undefined,
					width: 100,
				}
			}),
			states: { hover: { enabled: false, color: '#123456' } },
			//stack: ["consumption"],
			stack: undefined,
			stacking: undefined,
			fuelName: this.translator.instant(fuelName),
			dataStreamName: this.translator.instant(dataStreamName),
			color: 'rgb(255, 190, 10)',
			borderColor: 'rgb(255, 190, 10)',
			borderWidth: 1,
			dashStyle: null,
			grouping: false,
			groupPadding: 0.15,
			//pointPadding: 0,
			//pointWidth: 8,
			//minPointLength: 100,
			//maxPointWidth: 200,
			//minPointWidth: 100,
			customOptions: {
				isForecast: false,
			},
			zIndex: 5,
		}
		return this.chartHelper.applyRoundingToSeries([
			{ ...Object.assign({ ...default_series }, options) },
		])
	}
	private setup() {
		this.month = this.dateTranslator.getTranslatedMonth(
			this.period.getFirstDate().getMonth(),
		)
		this.month = this.translator.instant(this.month)
		this.year = this.period.getFirstDate().getFullYear()

		if (
			this.period.getPeriodType() === PeriodType.Year ||
			PeriodType.THIRTEENMONTH
		) {
			this.total = this.consumptionHelperService.summarizeArraysInMap(
				this.consumption,
			)
			this.isTotalAvailable = this.total !== null
		}

		this.setupHeaderTexts()

		let series = [] as any

		if (this.consumption) {
			const consMap = new Map<string, Map<string, number[]>>()
			const foreMap = new Map<string, Map<string, number[]>>()

			this.consumption.forEach((dataArr, fuel) => {
				dataArr = dataArr.map((data, index) => {
					return data
				})
				consMap.set(fuel, new Map([['consumption', dataArr]]))
			})
			this.forecast.forEach((dataArr, fuel) =>
				foreMap.set(fuel, new Map([['consumption', dataArr]])),
			)

			series = series.concat(
				this.chartHelper.getEnergyDataSeries(
					consMap,
					foreMap,
					'eliq-chart',
					this.period,
					this.resolutionType,
				),
			)
		}

		if (
			series &&
			Array.isArray(series) &&
			this.temperatures &&
			this.temperatures.length > 0
		) {
			series = series.concat(
				this.chartHelper.getTemperatureSeries(this.temperatures, this.period),
			)
		}

		if (this.production && !!this.production?.data) {
			series = series.concat(this.createProductionLikeSeries(this.production))
		} else if (this.importData && !!this.importData?.data) {
			const importColor = (this as any).themeService.getPropVal(
				'column-elec-import-color',
			)
			series = series.concat(
				this.createProductionLikeSeries(
					this.importData,
					'common.import',
					'common.import',
					{
						color: importColor,
						borderColor: importColor,
					},
				),
			)
			if (this.exportData) {
				const exportColor = (this as any).themeService.getPropVal(
					'column-elec-export-color',
				)
				series = series.concat(
					this.createProductionLikeSeries(
						this.exportData,
						'common.export',
						'common.import',
						{
							color: exportColor,
							borderColor: exportColor,
						},
					),
				)
			}
		}

		if (!series || series.length === 0) {
			this.errorMessage = 'home_consumption_summary_month.not_enough_data'
		}

		const categories = this.chartHelper.getCategories(
			this.period,
			undefined,
			this.categoryType,
		)
		const options: any = this.getHighChartsOptions(categories, series)
		if (this.Highcharts) {
			this.myChart = this.Highcharts.chart(
				this.getChartContainerElement(),
				options,
			)
			this.myChart.reflow()
		} else {
			this.getHighchartsAndDrawChart(options)
		}
	}

	private setupHeaderTexts() {
		// Cleaning the variables in case the location was changed.
		this.headerKey = ''
		this.headerKeyData = ''
		this.headerValue = ''
		this.headerValueKey = ''
		const myUnitType = this.unit === 'cost' ? 'cost' : 'consumption'

		if (this.period.getPeriodType() === 'month') {
			const month = this.dateTranslator.getTranslatedMonth(
				this.period.getFirstDate().getMonth(),
				false,
				true,
			)
			this.headerKey = `home_daily_${myUnitType}_summary_graph.daily_${myUnitType}_in_${this.period.getPeriodType()}`
			this.headerKeyData = { month: month }
		} else if (this.period.getPeriodType() === 'year') {
			this.headerKey = `home_monthly_${myUnitType}_summary_graph.monthly_${myUnitType}_in_${this.period.getPeriodType()}`
			this.headerKeyData = { year: this.year }
		} else if (this.period.getPeriodType() === 'thirteenmonth') {
			this.headerKey =
				'insights_comparison_month.total_cost' +
				` ${this.month} ${this.year} - ${this.month} ${this.year + 1}`
		}

		if (
			(this.period.getPeriodType() === 'year' ||
				this.period.getPeriodType() === 'thirteenmonth') &&
			this.isTotalAvailable
		) {
			this.headerValue = this.eliqNumberPipe.transform(this.total, this.unit)
			if (this.period.getPeriodType() === 'year') {
				this.headerValueKey = `home_monthly_${myUnitType}_summary_graph.total_${myUnitType}_so_far_this_year`
			}
		}
	}

	private async getHighchartsAndDrawChart(options: any) {
		this.chartHelper.getHighcharts().then((hc) => {
			this.Highcharts = hc
			this.myChart = this.Highcharts.chart(
				this.getChartContainerElement(),
				options,
			)
			this.containerElement =
				document.getElementById('chart-container') ?? new HTMLElement()
			this.myChart.reflow()
		})
	}

	clickedToggle() {
		const series = this.myChart.get('gas')
		if (series.visible) {
			series.hide()
		} else {
			series.show()
		}
	}

	private getHighChartsOptions(categories: string[], series: any[]) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const myThis = this
		return {
			chart: {
				type: 'column',
				backgroundColor: 'transparent',
				//margin: [0, 10, 25, 40],
				scrollablePlotArea: {
					minWidth: this.scrollablePlotAreaMinWidth,
					scrollPositionX: 0,
					opacity: 0.7,
				},
				reflow: true,
				style: {
					fontFamily: 'inherit',
				},
			},
			credits: {
				enabled: false,
			},
			tooltip: {
				borderWidth: 0,
				borderColor: '#E3E3E3',
				backgroundColor: 'rgba(255, 255, 255, 0)',
				borderRadius: 0,
				followTouchMove: false,
				shadow: false,
				useHTML: true,
				headerFormat: '',
				outside: true,
				shared: false,
				style: {
					fontSize: '14px',
				},
				formatter() {
					// get the index
					const index: number = (this as any).point?.index
					// collect the points
					const points: any[] = []
					;(this as any).point.series.chart.series.forEach((serie: any) => {
						if (serie.visible && serie.data[index] !== undefined) {
							if (serie.data[index].y !== 0 && serie.data[index].y !== null) {
								points.push(serie.data[index])
							}
						}
					})
					const background = `<div class="eliq-tooltip-bg">`
					let lines =
						background +
						`<div id="homechart" class="chart-tooltip-text" style="margin: 4px 10px 0px 10px; line-height: 18px;
             font-family: inherit; color: ${(
								myThis as any
							).themeService.getProp('tooltip-text-color')}">`

					points.forEach((p) => {
						let myColor = ''
						if (typeof p.color !== 'string') {
							// then it is a linear gradient object. lets make this object into a string.
							myColor =
								'background: linear-gradient(' +
								p.color.stops[0][1] +
								', ' +
								p.color.stops[1][1] +
								')'
						} else {
							myColor = 'background-color: ' + p.color
						}

						const name =
							p.series.userOptions['fuelName'] ||
							p.series.userOptions['dataStreamName'] ||
							p.series.userOptions['name']

						// TODO we really need to refactor our charts, lots of logic is duplicated between home chart and usage/insights chart.
						// I can't see a valid reason for the homechart and the insights chart being different components.

						// copied over from usage-chart-two
						let tooltipColumnMagnitude = p.y
						if (p.series?.userOptions?.customOptions?.isForecast) {
							// If the current column is a forecast column that has a consumption column on the same x-axis index,
							// then the tooltip will display the REMAINING forecasted consumption instead of the forecasted total consumption, which is confusing.
							// The code here will:
							//   1. Find the consumption series.
							//   2. If it exists then add it to the forcast column's tooltip value.
							const consumptionSeries = p.series.chart.series.find(
								(serie) =>
									serie.userOptions.id.includes('consumption') &&
									!serie.userOptions.customOptions.isForecast,
							)
							if (consumptionSeries) {
								const consumption = consumptionSeries.data[index]?.y
								if (consumption) {
									tooltipColumnMagnitude += consumption
								}
							}
						}
						// end of copied over from usage-chart-two

						lines = lines.concat(
							'<p style="margin: 0; margin-bottom: 4px;"><span class="dot" style="' +
								myColor +
								'; border-radius: 50%; width: 10px; height: 10px; display: inline-block;' +
								' margin-right: 8px;align-content: center;"></span><span>' +
								name +
								': <span style="font-weight: 600;">' +
								myThis.formatNumber(
									tooltipColumnMagnitude,
									p.series.userOptions.id,
								) +
								' </span>' +
								'</span></p>',
						)
					})
					return lines.concat('</div></div>')
				},
				footerFormat: '',
			},
			title: {
				text: undefined,
			},
			plotOptions: {
				column: {
					stacking: undefined,
				},
				series: {
					states: {
						inactive: {
							opacity: 1,
						},
					},
					borderWidth: 1,
					borderColor: '#636363',
					pointPadding: 0.01,
				},
			},
			legend: {
				enabled: false,
			},
			xAxis: {
				categories: categories,
				labels: {
					allowOverlap: false,
					autoRotation: undefined,
					step: 1,
					style: {
						color: (myThis as any).themeService.getProp('axis-labels'),
						fontSize: '14px',
					},
					overflow: 'justify',
				},
				lineWidth: 0,
			},
			yAxis: [
				{
					endOnTick: true,
					min: 0,
					max: series.length === 0 ? 100 : undefined,
					gridLineWidth: 0,
					title: {
						enabled: true,
						reserveSpace: true,
						text: this.getUnitSymbol(this.unit),
						//textAlign: 'center',
						rotation: 0,
						align: 'high',
						//y: -10,
						y: 0,
						style: {
							color: (myThis as any).themeService.getProp('axis-labels'),
							fontSize: '14px',
						},
					},
					labels: {
						reserveSpace: true,
						enabled: true,
						formatter: (value: any) => value.value,
						style: {
							color: (myThis as any).themeService.getProp('axis-labels'),
							fontSize: '14px',
						},
					},
					lineColor: (myThis as any).themeService.getProp('axis-lines'),
					gridLineColor: (myThis as any).themeService.getProp('grid-lines'),
				},
				{
					gridLineWidth: 2,
					endOnTick: true,
					// labels: {
					// 	enabled: true,
					// 	//reserveSpace: true,
					// 	//crop: false,
					// 	//overflow: 'none',
					// 	formatter: (value: any) => value.value,
					// 	style: {
					// 		color: (myThis as any).themeService.getPropVal('axis-labels'),
					// 		fontSize: '14px',
					// 	},
					// 	x: -10,
					// },
					// title: {
					// 	enabled: false,
					// 	text: 'C°',
					// 	reserveSpace: true,
					// 	crop: false,
					// 	overflow: 'none',
					// 	y: -10,
					// 	x: -10,
					// },
					opposite: true,
					title: {
						enabled: true,
						text: '°C',
						align: 'high',
						y: 0,
						//textAlign: 'center',
						//offset: 0,
						rotation: 0,
						style: {
							color: this.themeService.getPropVal('axis-labels'),
							fontSize: '14px',
						},
						//x: 0,
						//y: 0,
					},
					labels: {
						enabled: true,
						formatter: this.yAxisLabelFormatter,
						style: {
							color: this.themeService.getPropVal('axis-labels'),
							fontSize: '14px',
						},
					},
				},
				{
					gridLineWidth: 0,
					labels: {
						enabled: false,
					},
					title: {
						text: undefined,
					},
				},
			],
			series: series,
		}
	}

	private formatNumber(value: number, seriesId?: string) {
		if (seriesId === 'temperature') {
			return this.decPipe.transform(value, '1.0-0') + ' &deg;C'
		} else {
			return this.eliqNumberPipe.transform(value, this.unit)
		}
	}

	private getUnitSymbol(unit: string): string {
		return unit === 'energy'
			? 'kWh' // energy
			: unit === 'cost'
			? this.currencyPipe._getCurrencySymbol('narrow') // cost
			: 'm³' // m3
	}

	private yAxisLabelFormatter = (value: any) => {
		return value.value
	}
}
