<div class="container">
	<h5 class="primary-h5 header">Having trouble logging in?</h5>
	<p class="primary-p2" style="margin: 5px 0;">
		Contact our Customer Care team:
	</p>
	<div class="buttons" *ngIf="contact$ | async as contact">
		<ng-container *ngFor="let email of contact.email">
			<div class="button">
				<a [href]="'mailto:' + email">
					<eliq-link [label]="email"></eliq-link>
				</a>
			</div>
		</ng-container>

		<div class="button">
			<a [href]="'tel:' + contact.phone">
				<eliq-link [label]="contact.phone"></eliq-link>
			</a>
		</div>
	</div>

	<p class="primary-p3">
		8am - 6pm Monday to Friday
		<br />
		Voice messaging at all other times
	</p>

	<div style="margin-top: 1rem;">
		<eliq-basic-button label="OK" (clicked)="close()"></eliq-basic-button>
	</div>
</div>
