import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { JsonGetterService } from '@eliq/data-access'
import { map } from 'rxjs/operators'
import { BasicButtonComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'
import { NgIf, NgFor, AsyncPipe } from '@angular/common'
import { SkeletonDirectiveStyleComponent } from "../../../../../../../ui/src/lib/layout/skeletal-loading/skeleton-directive/skeleton.directive";

@Component({
	selector: 'eliq-jersey-having-trouble-modal',
	templateUrl: './jersey-having-trouble-modal.component.html',
	styleUrls: ['./jersey-having-trouble-modal.component.scss'],
	standalone: true,
	imports: [NgIf, NgFor, LinkComponent, BasicButtonComponent, AsyncPipe, SkeletonDirectiveStyleComponent],
})
export class JerseyHavingTroubleModalComponent implements OnInit {
	constructor(
		private dialogRef: MatDialogRef<JerseyHavingTroubleModalComponent>,
		private config: JsonGetterService,
	) {}

	public email$ = this.config.getContact().pipe(map((config) => config.email))
	public phone$ = this.config.getContact().pipe(map((config) => config.phone))

	public contact$ = this.config.getContact()

	ngOnInit(): void {}

	close() {
		this.dialogRef.close()
	}
}
