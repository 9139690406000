import { Injectable, PLATFORM_ID, inject } from '@angular/core'
import {
	HttpInterceptor,
	HttpRequest,
	HttpHandler,
	HttpResponse,
	HttpHeaders,
} from '@angular/common/http'
import { map, switchMap, take } from 'rxjs/operators'
import { of, throwError } from 'rxjs'
import { EnvironmentService } from '@eliq/data-access'
import { CacheService } from '@eliq/data-access/cache/cache.service'
import { CookieService } from '@eliq/data-access/cookie'

/**
 * URLs containing these strings will be cached,
 * unless they also contain any of the strings in NON_CACHEABLE_URLS.
 *
 * Example: /locations/1234/consumption will be cached,
 * but /locations/1234/budgets will not.
 */
const CACHEABLE_URLS = [
	'/locations',
	'/billingaccounts',
	'/translations',
	'/consumption',
]
const NON_CACHEABLE_URLS = [
	'/budgets',
	'/notifications/settings',
]

interface HttpCacheEntry<T> {
	body?: T
	headers?: HttpHeaders | undefined
	status?: number | undefined
	statusText?: string | undefined
	url?: string | undefined
}

@Injectable({
	providedIn: 'root',
})
export class CachingInterceptor implements HttpInterceptor {
	constructor(
		private cookieService: CookieService,
		private cache: CacheService,
		private env: EnvironmentService,
	) {}

	public PLATFORM_ID = inject(PLATFORM_ID)

	intercept(req: HttpRequest<unknown>, next: HttpHandler) {
		//return next.handle(req)

		if (!this.isRequestCachable(req)) {
			if (this.shouldClearCache(req)) {
				this.cache.clear()
			}
			return next.handle(req)
		}

		const requestCacheKey = this.cache.makeHttpCacheKey(req)
		//console.log("DEBUG requestCacheKey:", requestCacheKey)

		return this.cache.get<HttpCacheEntry<unknown>>(requestCacheKey).pipe(
			take(1),
			switchMap((cachedResponse: HttpCacheEntry<unknown> | null) => {
				if (cachedResponse) {
					if (typeof cachedResponse === 'string') {
						try {
							//console.log("DEBUG returning from cache:", new HttpResponse<unknown>(JSON.parse(cachedResponse)))
							return of(new HttpResponse<unknown>(JSON.parse(cachedResponse)))
						} catch (e) {
							console.error('Failed to parse cached response: ', e)
							cachedResponse = null
						}
					} else {
						//console.log("DEBUG returning from cache (not string):", new HttpResponse<unknown>(cachedResponse as any))
						return of(new HttpResponse<unknown>(cachedResponse as any))
					}
				}

				if (!cachedResponse) {
					return next.handle(req).pipe(
						map((event) => {
							//console.log("DEBUG caching response:", event)
							const eventClone = new HttpResponse<unknown>(event as any)
							//console.log("DEBUG caching eventClone:", eventClone)

							if (
								event instanceof HttpResponse &&
								event.status >= 200 &&
								event.status < 300 &&
								event.ok
							) {
								//console.log("DEBUG actually caching response:", event)

								this.cache.set<unknown>(requestCacheKey, {
									body: event['body'] ?? undefined,
									headers: event['headers'] ?? undefined,
									status: event['status'] ?? undefined,
									statusText: event['statusText'] ?? undefined,
									url: event['url'] ?? undefined,
								})
								return new HttpResponse<unknown>(event as any)
							}
							return event
						}),
					)
				}
				return throwError(() => new Error('Unknown error'))
			}),
		)
	}

	private isRequestCachable(req: HttpRequest<any>) {
		return req.method === 'GET' && this.matchesCacheableUrl(req.url)
	}

	private shouldClearCache(req: HttpRequest<any>) {
		return req.method !== 'GET' && req.url.includes('eliq.io')
	}

	private matchesCacheableUrl(url: string) {
		const matchesUrl = (candidate: string) => url.indexOf(candidate) > -1
		if (NON_CACHEABLE_URLS.some(matchesUrl)) {
			return false
		}
		return CACHEABLE_URLS.some(matchesUrl)
	}
}
