import { Component, EventEmitter, Output } from '@angular/core'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'
import { TranslateModule } from '@ngx-translate/core'
import { BasicButtonComponent } from '@eliq/ui/common/components/buttons/basic-button/basic-button.component'
import { CommonModule } from '@angular/common'

@Component({
	selector: 'eliq-home-profile-card',
	templateUrl: './home-profile-card.component.html',
	standalone: true,
	imports: [CardComponent, TranslateModule, BasicButtonComponent, CommonModule],
})
export class HomeProfileCardComponent {
	@Output() addProfileClick = new EventEmitter()
}
