<eliq-energy-advisor-card
	[header]="header"
	[comingSoon]="comingSoon"
	[loaded]="loaded"
	[buttonLabel]="buttonLabel"
	[buttonRoute]="buttonRoute"
	[text]="text"
	[estimatedSavings]="estimatedSavings"
	[imgSrc]="imgSrc | imgSrc"
	(cardClicked)="navigateToEnergyAdvisor()"
></eliq-energy-advisor-card>
