<eliq-card [ngStyle]="cardStyle" style="padding: 24px;" class="container">
	<img
		*ngIf="imgSrc"
		[src]="imgSrc"
		alt=""
		width="60"
		height="auto"
		style="max-height: 60px;"
		[ngStyle]="imageStyle"
		[ngClass]="
      {'long-shadow': imageLongShadow === true, 'long-shadow-black-5': imageLongShadow === 'black-5'}
    "
	/>
	<section class="card-content">
		<div class="text-part co2-card-text">
			<h6 class="header primary-h6">{{title}}</h6>
			<p class="secondary-p2" [innerHTML]="text"></p>
		</div>

		<a *ngIf="link" [href]="link" (click)="onLinkClick($event, link)" style="align-self: flex-start;" target="_blank">
			<eliq-flexible-button
				buttonId="home_info_card_link_button"
				[label]="linkText"
				[buttonStyle]="linkStyle"
			></eliq-flexible-button>
			<!-- <eliq-basic-button
				*ngIf="link && linkStyle === 'primary-button'"
				[label]="linkText"
				[variant]="_typeof(linkStyle) === 'string' ? (linkStyle === 'primary-button' ? 'primary' : 'secondary') : 'primary'"
			></eliq-basic-button> -->
		</a>
	</section>
</eliq-card>
