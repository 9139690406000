import { map } from 'rxjs/operators'

import {
	Component,
	EventEmitter,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core'
import { Router } from '@angular/router'
import { EliqNumberPipe } from '@eliq/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { Location as ModelsLocation } from '@eliq/models'
import { TranslateService } from '@ngx-translate/core'

import { AdviceStatus, APIAdvice } from '../../models/api-advice.model'
import {
	AdviceMap,
	EnergyAdvisorApiService,
} from '../../services/energy-advisor-api.service'
import { TipsTrackingService } from '../../tracking/tips-tracking.service'
import { EnergyAdvisorCardComponent } from '../energy-advisor-card/energy-advisor-card.component'

@Component({
	selector: 'eliq-energy-advisor-card-container',
	templateUrl: './energy-advisor-card-container.component.html',
	styleUrls: ['./energy-advisor-card-container.component.css'],
	standalone: true,
	imports: [EnergyAdvisorCardComponent, ImgSrcPipe],
})
export class EnergyAdvisorCardContainerComponent implements OnInit {
	@Input() comingSoon = false
	@Output() loading = new EventEmitter<boolean>()

	private numberPipe = inject(EliqNumberPipe)

	public loaded = false

	public header = ''
	public estimatedSavings = ''
	public text = ''
	public buttonLabel = ''
	public imgSrc = '/assets/images/home_tips.svg'
	public buttonRoute = ''
	private newAdvices: APIAdvice[]

	public loc: ModelsLocation

	constructor(
		private translator: TranslateService,
		private api: EnergyAdvisorApiService,
		private router: Router,
		private tracking: TipsTrackingService,
	) {}

	getTranslationWithFallback(key: string, fallback: string) {
		return this.translator.get(key).pipe(
			map((res: string) => {
				if (res === key) {
					return fallback
				}
				return res
			}),
		)
	}

	getAdviceTitle(advice: APIAdvice) {
		return this.getTranslationWithFallback(
			`energy_advisor.${advice.key}_title`,
			advice.title,
		)
	}

	getAdviceContent(advice: APIAdvice) {
		return this.getTranslationWithFallback(
			`energy_advisor.${advice.key}_text`,
			advice.content,
		)
	}

	getAdviceEstimatedSavings(advice: APIAdvice) {
		return this.translator.get('energy_advisor.savings_year', {
			amount: this.estimatedSavingFormatted(advice),
		})
	}

	private estimatedSavingFormatted(advice: APIAdvice) {
		return this.numberPipe.transform(
			advice.estimated_yearly_savings_energy,
			'energy',
			'1.0-0',
		)
	}

	ngOnInit(): void {
		if (this.comingSoon) {
			this.setupComingSoon()
			return
		} else {
			this.setLoaded(false)
			this.api.getAdviceMap('energy').subscribe({
				next: (advices) => {
					this.loc = advices.usedLocation as ModelsLocation
					this.setup(advices)
					this.setLoaded(true)
				},
				error: () => {
					this.header = this.translator.instant(
						'energy_advisor.fill_out_home_profile_for_tips',
					)
					this.text = ''
					this.newAdvices = []
					this.buttonLabel = ''
					this.setLoaded(true)
				},
			})
		}
	}

	private setup(advices: AdviceMap) {
		if (advices.allAdvices.length > 0) {
			if (
				advices.allAdvices.some((advice) => advice.status === AdviceStatus.None)
			) {
				this.newAdvices = advices.newAdvices

				this.translator.get('energy_advisor.new_tips_for_you').subscribe(
					(res) => {
						this.header = res
					},
				)

				const advice = this.newAdvices[0]
				this.getAdviceTitle(advice).subscribe((res) => {
					this.text = res
				})
				this.getAdviceEstimatedSavings(advice).subscribe((res) => {
					this.estimatedSavings = res
				})
			} else {
				this.newAdvices = []
				this.translator.get('energy_advisor.your_top_tip').subscribe((res) => {
					this.header = res
				})

				const advice = advices.allAdvices[0]
				this.getAdviceTitle(advice).subscribe((res) => {
					this.text = res
				})
				this.getAdviceEstimatedSavings(advice).subscribe((res) => {
					this.estimatedSavings = res
				})
			}
			this.translator.get('energy_advisor.view_all_tips')
				.subscribe((res) => this.buttonLabel = res)
		} else {
			this.newAdvices = []
			this.translator
				.get('energy_advisor.no_tips_available')
				.subscribe((res) => {
					this.header = res
				})
			this.translator
				.get('energy_advisor.no_tips_available_check_back_later')
				.subscribe((res) => {
					this.text = res
				})
			this.estimatedSavings = ''
			this.buttonLabel = ''
		}
	}

	private setupComingSoon() {
		this.translator.get('energy_advisor.coming_soon').subscribe((res) => {
			this.header = res
		})
		this.translator
			.get('energy_advisor.coming_soon_description')
			.subscribe((res) => {
				this.text = res
			})
	}

	navigateToEnergyAdvisor() {
		this.tracking.viewAllTips()
		this.router.navigate(['energy-advisor'])
	}

	private setLoaded(loaded: boolean) {
		// Checking if load status has changed to avoid emiting same status multiple times
		if (loaded !== this.loaded) {
			this.loaded = loaded
			this.loading.emit(!loaded)
		}
	}
}
