// @ts-nocheck
import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class ConsumptionHelperService {
	constructor() {}

	public findNotNullRangeOfArray(data: any[]) {
		let first = 0
		let last = 0
		let hasFoundFirst = false

		data.forEach((dataPoint, index) => {
			if (dataPoint !== null && dataPoint !== undefined) {
				if (!hasFoundFirst) {
					hasFoundFirst = true
					first = index
				} else if (index > last) {
					last = index
				}
			}
		})
		return [first, last]
	}

	public findNotNullRangeOf2DArray(data: any[][] | Map<string, any[]>) {
		let first = 0,
			last = 0

		data.forEach((dataArray) => {
			const [_first, _last] = this.findNotNullRangeOfArray(dataArray)

			if (first === undefined || _first < first) {
				first = _first
			}

			if (_last === undefined || _last > last) {
				last = _last
			}
		})

		return [first, last]
	}

	public summarizeArraysInMap(data: Map<string, number[]>): number {
		if (data === undefined || data === null) {
			return 0
		}

		let sum = 0

		for (const key of data.keys()) {
			const numbers = data.get(key)
			numbers.forEach((el) => (sum += el))
		}

		return sum
	}

	/**
	 *
	 * @param data array of numbers
	 * @param cutoff index where to stop summarizing. if undefined, the array will just be summarized in its entirety
	 */
	public summarizeArrayToCutoff(data: number[], cutoff: number): number {
		if (data === undefined || data === null) return 0

		if (data.length < cutoff || cutoff === undefined) cutoff = data.length

		let sum = 0
		for (let i = 0; i < cutoff; i++) {
			if (data[i] !== null) sum += data[i]
		}

		return sum
	}

	public arrayHasNonNullValue(array: number[]): boolean {
		return array.some((p) => p !== null && p !== 'Infinity')
	}

	public arrayHasNonNullZeroValue(array: number[]): boolean {
		return array.some((p) => p !== null && p !== 0 && p !== 'Infinity')
	}

	/**
	 * Returns true if any data array in the map is only nulls. Otherwise false.
	 * Returns undefined if the map's size is 0.
	 * @param map
	 * @returns
	 */
	public mapHasAnyInstanceOfOnlyNullArray(map: Map<string, number[]>): boolean {
		if (map.size == 0) return undefined
		let toReturn = false

		map.forEach((arr, key) => {
			const isOnlyNullArr = !this.arrayHasNonNullValue(arr)
			if (isOnlyNullArr) toReturn = true
		})

		return toReturn
	}

	/**
	 * Summarizes the given arrays based on what indexes they have in common (they are not null)
	 * Returns an array of numbers in the same order that arrays were passed in
	 * @param arrays the arrays to summarize that which they have in common
	 */
	public summarizeIndexesInCommon(arrays: number[][]): number[] {
		if (arrays.length === 0) return []

		const sum: number[] = []
		// create one sum value for each array passed in
		arrays.forEach(() => {
			sum.push(0)
		})

		// start off by getting the shortest length of the provided arrays.
		let shortestLength = arrays[0].length
		arrays.forEach((array) => {
			if (array.length < shortestLength) shortestLength = array.length
		})

		for (let i = 0; i < shortestLength; i++) {
			const someArrayHasNull = arrays.some((array) => {
				return array[i] === null
			})

			if (!someArrayHasNull) {
				arrays.forEach((array, index) => {
					sum[index] += array[i]
				})
			}
		}

		return sum
	}

	public generateSummarizedArrayFromMap(data: Map<string, number[]>): number[] {
		if (data === undefined || data === null) {
			return null
		}

		const sum = 0
		const array: number[] = []
		for (const key of data.keys()) {
			const numbers = data.get(key)
			numbers.forEach((el, i) => {
				if (array.length - 1 < i) {
					array.push(el)
				} else {
					array[i] += el
				}
			})
		}
		return array
	}

	public summarizeArray(arr: number[]) {
		if (typeof arr === 'undefined' || !Array.isArray(arr)) return 0

		let sum = 0
		arr.forEach((value) => {
			if (value !== null) sum += value
		})
		return sum
	}

	/**
	 * returns the average for an array of numbers
	 * will only count the non-null data points
	 * if there are only null data points, returns undefined
	 * @param data array of numbers
	 */
	public getAverage(data: number[]): number {
		if (!this.arrayHasNonNullValue(data)) return undefined
		let count = 0
		let sum = 0

		data.forEach((p) => {
			if (p !== null) {
				count++
				sum += p
			}
		})

		return sum / count
	}

	public getIndexOfLastEntryWithValue(data: number[]) {
		let index
		for (let i = data.length - 1; i >= 0; i--) {
			if (data[i] !== null) {
				return i
			}
		}

		return index
	}

	/**
	 * takes in an array of consumption and forecast of equal lengths and determines if their values overlap at any index
	 * if there is an overlap, the overlapping values will be removed from the original arrays and placed into their own overlap-arrays
	 * these new data structure can easily be used to draw properly in highcharts (mainly regarding border-rounding)
	 *
	 * this function does not modify the two passed arrays (no side-effects)
	 * @param consumption
	 * @param forecast
	 * @returns a set of values. look at hasOverlap flag first. if hasOverlap is false, all else is undefined.
	 */
	public splitConsIfOverlap(
		consumption: Map<string, number[]>,
		forecast: Map<string, number[]>,
	): {
		consumptionWithoutOverlap: Map<string, number[]>
		consumptionOverlap: Map<string, number[]>
		hasOverlap: boolean
	} {
		// we get the longest forecast here which is used to determine the overlaps for the consumption arrays.
		// this is what we measure an overlap against
		const longestForecast = this.getArrayWithLongestForecast(forecast)

		// variables which will be returned that are populated below going forward
		const consumptionWithoutOverlap: Map<string, number[]> = new Map()
		const consumptionWithOverlap: Map<string, number[]> = new Map()
		let hasOverlap = false

		consumption.forEach((values, fuel) => {
			// copy the data into new arrays so we dont create unwanted side-effects.
			const consumptionCopy = Object.assign([], values)

			// overlaps is a list of indexes where overlaps exist.
			const overlapIndices: number[] = []
			consumptionCopy.forEach((val, index) => {
				if (
					consumptionCopy[index] !== null &&
					longestForecast[index] !== null
				) {
					overlapIndices.push(index)
				}
			})

			if (overlapIndices.length === 0) {
				consumptionWithoutOverlap.set(fuel, consumptionCopy)
			} else {
				hasOverlap = true

				const consOverlap = this.makeArrayWithNulls(consumptionCopy.length)

				// for each overlap index, place it in the correct place in the overlap-array.
				// then remove it from its original array
				overlapIndices.forEach((overlapIndex) => {
					consOverlap[overlapIndex] = consumptionCopy[overlapIndex]
					consumptionCopy[overlapIndex] = null
				})

				consumptionWithoutOverlap.set(fuel, consumptionCopy)
				consumptionWithOverlap.set(fuel, consOverlap)
			}
		})

		return {
			consumptionWithoutOverlap: consumptionWithoutOverlap,
			consumptionOverlap: consumptionWithOverlap,
			hasOverlap: hasOverlap,
		}
	}

	private makeArrayWithNulls(nNulls: number): number[] {
		const arr: number[] = []
		let i = 0
		while (i < nNulls) {
			arr.push(null)
			i++
		}
		return arr
	}

	private getArrayWithLongestForecast(map: Map<string, number[]>): number[] {
		const counts: Map<string, number> = new Map()
		map.forEach((array, key) => {
			let count = 0
			array.forEach((value) => {
				if (value !== null) {
					count++
				}
			})

			counts.set(key, count)
		})

		let longestArrayKey = ''
		let longestArrayCount: number = undefined

		counts.forEach((count, key) => {
			if (count > longestArrayCount || longestArrayCount === undefined) {
				longestArrayCount = count
				longestArrayKey = key
			}
		})

		return map.get(longestArrayKey)
	}

	public mapValues(
		map: Map<string, number[]>,
		fn: (n: number) => number,
	): Map<string, number[]> {
		if (map === undefined) return undefined
		const newMap: Map<string, number[]> = new Map<string, number[]>()

		map.forEach((array, key) => {
			newMap.set(
				key,
				array.map((p) => (p === null ? null : fn(p))),
			)
		})

		return newMap
	}
	/**
	 * Returns a new map where all consumption values have been divided by 1000.
	 * @param map
	 */
	public divideValuesBy1000(map: Map<string, number[]>): Map<string, number[]> {
		return this.divideValuesBy(map, 1000)
	}

	public divideValuesBy(
		map: Map<string, number[]>,
		n: number,
	): Map<string, number[]> {
		return this.mapValues(map, (p) => p / n)
	}

	public multiplyValuesBy(
		map: Map<string, number[]>,
		n: number,
	): Map<string, number[]> {
		return this.mapValues(map, (p) => p * n)
	}

	public subtractFromValues(
		usage: Map<string, number[]>,
		n: number,
	): Map<string, number[]> {
		return this.mapValues(usage, (p) => p - n)
	}

	public addToValues(
		usage: Map<string, number[]>,
		n: number,
	): Map<string, number[]> {
		return this.mapValues(usage, (p) => p + n)
	}

	/**
	 * Looks for overlaps between usage and forecast data. If an overlap is found, it will subtract the forecast value
	 * by the usage value found at the same index in the correspoding array.
	 * The original arrays are modified.
	 * @param usage usage data
	 * @param forecast forecast data
	 */
	public findOverlapAndSubtractForecast(
		usage: Map<string, number[]>,
		forecast: Map<string, number[]>,
	) {
		if (!forecast) return
		usage.forEach((values, key) => {
			if (forecast.has(key)) {
				values.forEach((value, i) => {
					if (value && forecast.get(key)[i]) {
						// we found the overlap
						const forecastVal = forecast.get(key)[i]
						forecast.get(key)[i] = forecastVal - value
					}
				})
			}
		})
	}
}
