import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	OnChanges,
	Pipe,
	PipeTransform,
} from '@angular/core'
import {
	ConsumptionHandlerService,
	ConsumptionHelperService,
	PeriodType,
	ResolutionType,
	DataGetterService,
	Location,
	PvHelperService,
	UnitType,
	CoreDataStoreService,
} from '@eliq/core'
import { DateHelper } from '@eliq/util'

import { Period } from '@eliq/core'
import { forkJoin, of, Subscription, throwError } from 'rxjs'
import {
	switchMap,
	take,
	tap,
	map,
	mergeMap,
	concatMap,
	catchError,
	finalize,
	retry,
} from 'rxjs/operators'
import { HomechartConfigService } from '../config/homechart-config.service'
import { LocationHttpService } from '@eliq/core'
import { APIData } from '@eliq/core/models/src/api-models/api-data.model'
import { EnvironmentService } from '@eliq/data-access'
import { HomechartComponent } from '../homechart/homechart.component'
import { NgIf } from '@angular/common'
import { UsagechartApiService } from '@eliq/feature/usagechart/services/usagechart-api.service'
import { TranslateModule } from '@ngx-translate/core'
import { TranslateOrFallbackPipe } from '@eliq/core/pipes/translate-or-fallback.pipe'
import { LottieComponent } from 'ngx-lottie'

@Component({
	selector: 'eliq-homechart-container',
	templateUrl: './homechart-container.component.html',
	styleUrls: ['./homechart-container.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		HomechartComponent,
		TranslateModule,
		LottieComponent,
		TranslateOrFallbackPipe,
	],
})
export class HomechartContainerComponent implements OnInit, OnChanges {
	@Input() location: Location
	@Input() locId: number
	@Input() period: Period
	@Input() resolutionType: ResolutionType
	@Input() fuels: string[]
	@Input() unit: 'cost' | 'energy' | 'm3'
	@Input() options: any = {} // default to empty object avoid annoying undefineds

	@Output() loading = new EventEmitter<boolean>()

	// internal variables
	private setupDone = false
	private initialUnit: 'cost' | 'energy' | 'm3'

	private dateCalc = DateHelper.getInstance()
	private sub: Subscription

	// variables specifically for passing along to presentational component
	public consumption: Map<string, number[]>
	public forecast: Map<string, number[]>
	public production: APIData
	public importData: APIData
	public exportData: APIData
	public categoryType = ''
	public temperatures: number[]
	public hasPV = false
	public waitingForPVDisagg = false

	public loaded = false

	constructor(
		private coreDS: CoreDataStoreService,
		private consHandler: ConsumptionHandlerService,
		private consHelper: ConsumptionHelperService,
		private config: HomechartConfigService,
		private consumptionHandlerService: ConsumptionHandlerService,
		private locHttp: LocationHttpService,
		private dataGet: DataGetterService,
		private environment: EnvironmentService,
		private usageService: UsagechartApiService,
		private pvHelper: PvHelperService,
	) {}

	ngOnInit(): void {
		this.initialUnit = this.unit
		this.setup()
		this.setupDone = true
	}

	ngOnChanges() {
		if (this.setupDone) {
			this.setLoading(true)
			this.unit = this.initialUnit // Resetting unit in case the location was changed.
			this.production = new APIData()
			this.consumption = new Map<string, number[]>()
			this.importData = new APIData()
			this.exportData = new APIData()
			this.temperatures = []
			// TODO refactor this

			this.setup()
		}
	}

	// private attempts = 0
	private setup() {
		if (this.sub) this.sub?.unsubscribe()
		this.setLoading(true)

		// this.attempts++
		// if (this.attempts < 10 && !this.location) {
		// 	setTimeout(() => this.setup(), 500)
		// 	return
		// } else if (this.attempts >= 10 && !this.location) {
		// 	console.error('No location provided')
		// 	return
		// }

		// this.pvHelper.locationHasPV().subscribe((hasPV) => {
		// 	this.hasPV = hasPV
		// })

		const fromDate = this.period.getFirstDate()
		const toDate = this.dateCalc.addOfPeriod(
			this.period.getPeriodType(),
			1,
			this.period.getFirstDate(),
		)

		this.sub = this.coreDS
			.getActiveLocation()
			.pipe(
				take(1),
				tap((location) => {}),
				switchMap((location) => {
					if (!location) {
						return throwError(
							() => new Error('No location in homechart-container'),
						)
					}
					this.location = location

					return this.pvHelper.getPvInfo(location).pipe(
						map((pvInfo) => {
							this.hasPV = pvInfo.hasPV
							this.waitingForPVDisagg = pvInfo.waitingForPVDisagg
							return pvInfo
						}),
						catchError((e, _c) => {
							console.error('Error in homechart-container.component.ts', e)
							return throwError(() => e)
						}),
					)
				}),
				switchMap((_pvInfo) => {
					return this.getData(this.unit, fromDate, toDate).pipe(
						switchMap((result) => {
							if (
								!this.showPV() &&
								this.unit == 'cost' &&
								this.consHelper.mapHasAnyInstanceOfOnlyNullArray(
									result?.cons as any,
								)
							) {
								this.unit = 'energy'

								return this.getData(this.unit, fromDate, toDate)
							} else {
								return of(result)
							}
						}),
						switchMap((result) => {
							// We only want to have at most one day where we have both consumption and forecast (the latest day with consumption)
							let numForecastConsOverlaps = 0
							;(result?.cons as any)?.forEach((consArray: any, key: string) => {
								const foreArray = result.fore.get(key)
								if (foreArray) {
									for (const [foreIndex] of foreArray.entries()) {
										// If there's both forecast and cons for this index,
										// and we've already found one before, set the previous index forecast to null.
										if (
											foreArray[foreIndex] &&
											consArray[foreIndex] &&
											numForecastConsOverlaps > 0
										) {
											// Cast it to any because it's defined as a number[], but we can have nulls in it.
											// TODO change the type to (number | null)[] instead.
											;(foreArray[foreIndex - 1] as any) = null
											numForecastConsOverlaps++
										}
									}
								}
								consArray
							})
							return of(result)
						}),
						finalize(() => {
							this.setLoading(false)
						}),
						catchError((e, c) => {
							console.error('Error in homechart-container.component.ts', e)
							return of(undefined as unknown)
						}),
						take(1),
					)
				}),
			)
			.pipe(retry({ count: 10, delay: 500 }))
			.subscribe()
	}

	private showPV() {
		return false // TODO I'm just hiding this for now
		if (!this.hasPV) {
			return false
		}

		if (
			this.environment.isUAT() &&
			localStorage.getItem('PV_DATA_ALLOWED') === 'NOT_ALLOWED'
		) {
			return false
		}

		return true
	}

	private getData(unit: 'cost' | 'energy' | 'm3', from: Date, to: Date) {
		return forkJoin({
			prod: (unit === 'energy' && this.showPV()
				? this.dataGet.getData(
						this.locId,
						'production',
						'elec',
						unit,
						this.resolutionType,
						from,
						to,
				  )
				: of(void 0 as unknown)
			).pipe(
				tap((prod) => {
					if (this.showPV()) {
						// I'm not sure why (or if, anymore, tbh) this extra check is required.
						this.production = this.prodPipe(prod as any)
					}
				}),
				catchError((e, c) => {
					return of(void 0 as unknown)
				}),
			),
			cons: (!(unit === 'cost' && this.showPV())
				? this.consHandler.getConsumptionForFuels(
						this.locId,
						this.fuels,
						unit,
						this.resolutionType,
						from,
						to,
				  )
				: of(void 0 as unknown)
			).pipe(
				tap((cons) => {
					this.consumption = this.energyPipe(unit, cons as any)
				}),
			),
			fore: this.consHandler
				.getForecastForFuels(
					this.locId,
					this.fuels,
					unit,
					this.resolutionType,
					from,
					to,
				)
				.pipe(tap((fore) => (this.forecast = this.energyPipe(unit, fore)))),
			config: this.config.getCategoryType().pipe(
				take(1),
				tap((type) => (this.categoryType = type)),
			),
			temperatures: this.config.getIsTemperatureVisible().pipe(
				switchMap((isVisible) =>
					isVisible
						? this.consumptionHandlerService.getTemperature(
								this.locId,
								this.resolutionType,
								from,
								to,
						  )
						: of(undefined),
				),
				take(1),
				tap((t) => (this.temperatures = t as any)),
			),
			importData: (unit === 'cost' && this.showPV()
				? this.dataGet.getData(
						this.locId,
						'import',
						'elec',
						unit,
						this.resolutionType,
						from,
						to,
				  )
				: of(undefined as unknown)
			).pipe(
				tap((import_data: unknown) => {
					if (this.showPV()) {
						// I'm not sure why (or if, anymore, tbh) this extra check is required.
						this.importData = import_data as any
					}
				}),
				catchError((e, c) => {
					return of(undefined as unknown)
				}),
			),
			exportData: (unit === 'cost' && this.showPV()
				? this.dataGet.getData(
						this.locId,
						'export',
						'elec',
						unit,
						this.resolutionType,
						from,
						to,
				  )
				: of(undefined as unknown)
			).pipe(
				tap((export_data) => {
					if (this.showPV()) {
						// I'm not sure why (or if, anymore, tbh) this extra check is required.
						this.exportData = export_data as any
					}
				}),
				catchError((e, c) => {
					return of(undefined as unknown)
				}),
			),
		})
	}

	private energyPipe(
		unit: 'cost' | 'energy' | 'm3',
		map: Map<string, number[]>,
	): Map<string, number[]> {
		if (unit === 'energy') {
			map.forEach((arr, key) => {
				map.set(
					key,
					arr.map((p) => (p != 0 && p != null ? p / 1000 : p)),
				)
			})
		}
		return map
	}

	private prodPipe(prodData: APIData): APIData {
		// Might want to do something here later
		prodData.data = prodData.data.map((p) =>
			p != 0 && p != null ? p / 1000 : p,
		)
		return prodData
	}

	private setLoading(loading: boolean) {
		const loaded = !loading
		// Checking if load status has changed to avoid emiting same status multiple times
		if (loaded !== this.loaded) {
			this.loaded = loaded
			this.loading.emit(!loaded)
		}
	}
}
