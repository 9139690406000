import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import {
	CommonConfigService,
	ConfigButton,
	ConfigButtonTypes,
} from '@eliq/core/config'
import { LinkComponent } from '../link/link.component'
import { BasicButtonComponent } from '../basic-button/basic-button.component'
import { NgIf, AsyncPipe } from '@angular/common'

@Component({
	selector: 'eliq-flexible-button',
	templateUrl: './flexible-button.component.html',
	styleUrls: ['./flexible-button.component.scss'],
	standalone: true,
	imports: [NgIf, BasicButtonComponent, LinkComponent, AsyncPipe],
})
export class FlexibleButtonComponent implements OnInit {
	@Input() buttonId = ''
	public configButton$!: Observable<ConfigButton>
	public configButtonTypes = ConfigButtonTypes

	@Input() label = ''
	@Input() variant: ConfigButton['type'] = ConfigButtonTypes.PRIMARY
	@Input() useEntireWidth = false
	@Input() disabled = false
	@Input() hidden = false
	@Input() custom = ''
	@Input() iconSrc = ''
	@Input() submit = false
	@Input() loading = false
	@Input() buttonStyle = {} as Record<string, any>
	@Input() leftIcon: undefined | false | string = undefined

	// only for underline button
	@Input() type = 'button'

	@Output() clicked = new EventEmitter()

	constructor(private config: CommonConfigService) {}

	ngOnInit(): void {
		this.configButton$ = this.config.getButton(this.buttonId)
		this.configButton$.subscribe((btn) => {
			this.buttonStyle = Object.assign(
				{},
				{ width: '100%', minWidth: 'max-content' },
				btn.style,
				this.buttonStyle || {},
			)
		})
	}
}
