// @ts-nocheck
/* eslint-disable prefer-spread */

import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import * as HighchartsBorderRadius from 'highcharts-border-radius'
import { DecimalPipe } from '@angular/common'
import {
	ConsumptionHelperService,
	fuelSorter,
	PeriodType,
	ResolutionType,
	DateTranslatorService,
	EliqCurrencyPipe,
	EliqDatePipe,
	EliqNumberPipe,
	Period,
} from '../../../../public_api'
import { DateHelper } from '@eliq/util'
import { addHours } from 'date-fns'
import addMinutes from 'date-fns/addMinutes'
import { EliqThemeService } from '@eliq/theme'
import { EnvironmentService } from '@eliq/data-access'

function normalizeCommonJSImport<T>(importPromise: Promise<T>): Promise<T> {
	// CommonJS's `module.exports` is wrapped as `default` in ESModule.
	return importPromise.then((m: any) => (m.default || m) as T)
}

const loadHighcharts = normalizeCommonJSImport(import('highcharts'))

// this function assumes that a = b. so b isnt even needed actually.
const stackSorter = (a: string, b: string) => {
	if (a == 'elecconsumption' || a == 'elecimport') return 1
	if (a == 'gasconsumption' || a == 'gasimport') return 1
	else return -1
}

@Injectable({
	providedIn: 'root',
})
export class ChartHelperService {
	private Highcharts: any

	private dateCalc: DateHelper
	private hasInitializedHighchartsBorderRadius = false

	constructor(
		private translator: TranslateService,
		private dateTranslator: DateTranslatorService,
		private consumptionHelper: ConsumptionHelperService,
		private currencyPipe: EliqCurrencyPipe,
		private decPipe: DecimalPipe,
		private datePipe: EliqDatePipe,
		private eliqNumberPipe: EliqNumberPipe,
		private themeService: EliqThemeService,
		private env: EnvironmentService,
	) {
		this.dateCalc = DateHelper.getInstance()
		this.loadHighcharts()
	}

	async loadHighcharts() {
		this.Highcharts = await loadHighcharts
	}

	public getYAxis(values: number[]) {
		const max = Math.max.apply(Math, values)
		const min = Math.min.apply(Math, values)

		return {
			min: min - 10,
			max: max + 10,
			gridLineWidth: 0,
			endOnTick: true,
			labels: {
				enabled: false,
			},
			title: {
				text: undefined,
			},
		}
	}

	public async getHighcharts() {
		if (!this.Highcharts) {
			this.Highcharts = await loadHighcharts
		}

		if (!this.hasInitializedHighchartsBorderRadius && this.Highcharts) {
			this.hasInitializedHighchartsBorderRadius = true
			if (HighchartsBorderRadius) {
				HighchartsBorderRadius(this.Highcharts)
			}
		}

		return this.Highcharts
	}

	public getMockedEnergyData(
		period: Period,
		resolution: ResolutionType,
		usageFn: (index: number) => number,
	) {
		const data = {
			usageFuels: new Map<string, Map<string, number[]>>(),
			forecastFuels: new Map<string, Map<string, number[]>>(),
		}

		const numberOfPoints = period.getDatesForResolution(resolution).length ?? 30

		;[data.usageFuels, data.forecastFuels].forEach((usageOrForecast) => {
			usageOrForecast.set('elec', new Map<string, number[]>())
			usageOrForecast
				.get('elec')
				.set('consumption', Array(numberOfPoints).fill(0).map(usageFn))
		})

		return data
	}

	/**
	 * Usage:
	 * this.chartHelper.getMockedEnergyDataSeries(
			'eliq-chart',
			this.period,
			this.period.getPossibleResolutions()[0],
			this.currentChartType
		),
	 */
	public getMockedEnergyDataSeries(
		mockedData: {
			usageFuels: Map<string, Map<string, number[]>>
			forecastFuels: Map<string, Map<string, number[]>>
		},
		colorKeyPrefix: string,
		period: Period,
		resolution: ResolutionType,
		chartType = 'column',
	): any[] {
		const { usageFuels, forecastFuels } = mockedData

		return this.getEnergyDataSeries(
			usageFuels,
			forecastFuels,
			colorKeyPrefix,
			period,
			resolution,
			chartType,
		)
	}

	public getEnergyDataSeries(
		usageFuels: Map<string, Map<string, number[]>>,
		forecastFuels: Map<string, Map<string, number[]>>,
		colorKeyPrefix: string,
		period: Period,
		resolution: ResolutionType,
		chartType = 'column',
	): any[] {
		let returnConsOverlapSeries = []
		let returnForeSeries = []
		let returnConsSeries = []
		const indexOverlaps = {}

		usageFuels.forEach((usageMap, fuelKey) => {
			const forecastMap = forecastFuels.get(fuelKey)

			const hasForecast =
				forecastMap &&
				usageMap.has('consumption') &&
				forecastMap.has('consumption')

			if (hasForecast) {
				const result = this.consumptionHelper.splitConsIfOverlap(
					usageMap,
					forecastMap,
				)

				if (result.hasOverlap) {
					usageMap = result.consumptionWithoutOverlap

					for (const dataStream of result.consumptionOverlap.keys()) {
						const _series = this.getSeries(
							dataStream,
							fuelKey,
							result.consumptionOverlap.get(dataStream),
							false,
							period,
							resolution,
							chartType,
							colorKeyPrefix,
						)
						if (_series.length > 0) {
							_series[0].customOptions ??= {}
							_series[0].customOptions.isOverlap = true
							_series[0].customOptions.indexOverlaps = {}

							if (typeof result.consumptionOverlap.entries === 'function') {
								for (const entry of result.consumptionOverlap.entries()) {
									indexOverlaps[entry[0]] = entry[1].map((x) => x === null)
								}
							}
							_series[0].customOptions.indexOverlaps = indexOverlaps
						}
						returnConsOverlapSeries = returnConsOverlapSeries.concat(_series)
					}
				}

				for (const dataStream of forecastMap.keys()) {
					const forecastData = forecastMap.get(dataStream)
					const usageData = usageMap.get(dataStream)

					// Connect forecast and data lines if it's not a column chart
					if (chartType !== 'column') {
						// Get first non null value in forecast data array
						const firstForecastDataPoint = forecastData.reduce(
							(prev, cur, index) => {
								if (prev < 0 && cur !== null) {
									return index
								} else {
									return prev
								}
							},
							-1,
						)

						// If we found any data in forecast,
						// append the consumption value before the first datapoint in forecast to the forecast.
						if (firstForecastDataPoint > 0) {
							const usageDataPoint =
								usageMap.get(dataStream)[firstForecastDataPoint - 1]
							forecastData[firstForecastDataPoint - 1] = usageDataPoint
						}
					}
					returnForeSeries = returnForeSeries.concat(
						this.getSeries(
							dataStream,
							fuelKey,
							forecastMap.get(dataStream),
							true,
							period,
							resolution,
							chartType,
							colorKeyPrefix,
						),
					)
				}
			}

			for (const dataStream of usageMap.keys()) {
				returnConsSeries = returnConsSeries.concat(
					this.getSeries(
						dataStream,
						fuelKey,
						usageMap.get(dataStream),
						false,
						period,
						resolution,
						chartType,
						colorKeyPrefix,
					),
				)
			}
		})

		returnConsSeries.forEach((serie) => {
			serie.customOptions ??= {}
			serie.customOptions.isOverlap = false
			serie.customOptions.indexOverlaps = indexOverlaps
		})

		let toReturn = returnConsOverlapSeries
			.concat(returnConsSeries)
			.concat(returnForeSeries)

		toReturn = this.applyRoundingToSeries(this.sortSeries(toReturn), false)

		return toReturn
	}

	private getSeries(
		dataStream: string,
		fuelKey: string,
		data: (null | number)[],
		isForecast: boolean,
		period: Period,
		resolution: ResolutionType,
		chartType: string,
		colorKeyPrefix: string,
	) {
		// if there are only nulls and undefineds in the data, we dont even want to return anything
		if (
			!data.some((p) => {
				return p
			})
		)
			return [] // undefined and null === false

		if (chartType === 'column')
			return this.getColumnSeries(
				dataStream,
				fuelKey,
				data,
				isForecast,
				period,
				resolution,
				colorKeyPrefix,
			)
		else if (chartType === 'area')
			return this.getAreaSeries(
				dataStream,
				fuelKey,
				data,
				period,
				resolution,
				colorKeyPrefix,
				isForecast,
			)
	}

	private getColumnSeries(
		dataStream: string,
		fuelKey: string,
		data: (null | number)[],
		isForecast: boolean,
		period: Period,
		resolution: ResolutionType,
		colorKeyPrefix: string,
	) {
		let color, borderColor
		const compositeKey = `${fuelKey}-${dataStream}`

		const isSmallScreen = false //window?.innerWidth < 1024;

		let areaColor = this.getColor(compositeKey, colorKeyPrefix, 'area')
		let areaLineColor = this.getColor(
			compositeKey + '-line',
			colorKeyPrefix,
			'area',
		)

		let hoverColor = ''
		let borderColorHover = ''
		if (isForecast) {
			areaColor = 'rgba(0,0,0,0)'
			areaLineColor = this.getColor(
				compositeKey + '-line',
				colorKeyPrefix,
				'area',
			)
			hoverColor = this.getColorGradient(
				`${compositeKey}-forecast--hover`,
				colorKeyPrefix,
				'column',
			)
			color = this.getColorGradient(
				`${compositeKey}-forecast`,
				colorKeyPrefix,
				'column',
			)
			borderColor = this.getColor(
				`${compositeKey}-forecast-border`,
				colorKeyPrefix,
				'column',
			)
			borderColorHover =
				this.getColor(
					`${compositeKey}-forecast-border--hover`,
					colorKeyPrefix,
					'column',
				) || borderColor
		} else {
			// consumption
			hoverColor = this.getColorGradient(
				`${compositeKey}--hover`,
				colorKeyPrefix,
				'column',
			)
			color = this.getColorGradient(compositeKey, colorKeyPrefix, 'column')
			borderColor = color
			borderColorHover =
				this.getColor(
					`${compositeKey}-forecast-border--hover`,
					colorKeyPrefix,
					'column',
				) || borderColor
		}

		const dates = period.getDatesForResolution(resolution)

		let states = { hover: { enabled: false, color: '#123456' } }
		if (hoverColor) {
			states = {
				hover: {
					enabled: true,
					color: hoverColor,
					borderColor: borderColorHover,
				},
			}
		}

		//this.interpolateNulls(data, isForecast)

		const data_changed = data.map((point, index) => {
			return {
				y: !point ? null : point,
				...(!this.env.isEnergia()
					? {
							color: !point ? 'rgb(0,0,0,0)' : undefined,
							borderColor: !point ? 'rgb(0,0,0,0)' : undefined,
					  }
					: {}),
				custom: {
					dateString: this.getDateString(
						dates[index],
						period.getPeriodType(),
						resolution,
					),
				},
			}
		})

		const toReturn = [
			{
				type: isSmallScreen ? 'area' : 'column',
				data: data_changed,
				states: states,
				color: isSmallScreen ? areaColor : color,
				margin: isSmallScreen ? 0 : undefined,
				padding: isSmallScreen ? 0 : undefined,
				borderColor: isSmallScreen ? undefined : borderColor,
				borderWidth: isSmallScreen
					? parseInt(
							this.themeService.getPropVal('chart-column-border-width-sm'),
					  ) || 0
					: parseInt(
							this.themeService.getPropVal('chart-column-border-width'),
					  ) || 0,
				fillColor: isSmallScreen ? areaColor : undefined,
				lineColor: isSmallScreen ? areaLineColor : undefined,

				stack: fuelKey + dataStream,
				stacking: 'normal',

				dashStyle: isForecast ? 'Dash' : undefined,
				id: compositeKey,
				fuel: fuelKey,
				fuelName:
					this.translator.instant('common.' + fuelKey) +
					(isForecast
						? ' (' +
						  this.translator.instant('common.forecast').toLowerCase() +
						  ')'
						: ''),
				dataStreamName:
					this.translator.instant('common.' + dataStream) +
					(isForecast
						? ' (' +
						  this.translator.instant('common.forecast').toLowerCase() +
						  ')'
						: ''),
				customOptions: {
					isForecast: isForecast,
				},
				zIndex: this.getZIndexForColumnSeries(dataStream),
			},
		]
		return toReturn
	}

	// Replaces the data in-place. If it's not a forecast, it replaces nulls with 0. If it is a forecast, it (linearly) interpolates between nulls.
	// TODO should see if this is even necessary, I had some issues with a rebel account (2336056) that made me think this was needed, but I'm pretty sure nulls should stay nulls.
	public interpolateNulls(data: (null | number)[], isForecast) {
		/**
		 * Interpolate between null values
		 */

		// If not forecast we want to make them 0
		if (true) {
			data.forEach((value, index) => {
				if (value === null) {
					data[index] = 500 // DEBUG, fixed an issue with the rebel account I mentioned above
				}
			})
			// If forecast we want to have a smooth transition
		} else {
			let lastNonNull: number | null = null
			let nextNonNull: number | null = null
			for (let i = data.length - 1; i >= 0; i--) {
				if (data[i] !== null) {
					// If it's not null then we save the last non null value
					lastNonNull = data[i]
				} else {
					// If it is null we want to interpolate between the last (closest to the right) non null value and the next non null value (closest to the left)
					for (let j = i; j >= 0; j--) {
						if (data[j] !== null) {
							nextNonNull = data[j]
							break
						}
					}
					// Do linear interpolation
					if (lastNonNull !== null && nextNonNull !== null) {
						data[i] =
							lastNonNull +
							((nextNonNull - lastNonNull) * (i - (i + 1))) / (i - (i + 1))
					}
				}
			}
		}
	}

	private getZIndexForColumnSeries(key: string): number {
		if (key == 'production' || key == 'export') return 10
		else return 5
	}

	private getAreaSeries(
		dataStream: string,
		fuelKey: string,
		data: (null | number)[],
		period: Period,
		resolution: ResolutionType,
		colorKeyPrefix: string,
		isForecast = false,
	) {
		const compositeKey = `${fuelKey}-${dataStream}`
		const color = this.getColor(compositeKey, colorKeyPrefix, 'area')
		const lineColor = this.getColor(
			compositeKey + '-line',
			colorKeyPrefix,
			'area',
		)

		const dates = period.getDatesForResolution(resolution)
		const serie = {
			type: isForecast ? 'line' : 'area',
			data: data.map((value, index) => {
				return {
					y: value, // was !value ? null : value
					custom: {
						dateString: this.getDateString(
							dates[index],
							period.getPeriodType(),
							resolution,
						),
					},
				}
			}),
			dashStyle: isForecast ? 'Dash' : 'Solid',
			color: lineColor,
			fillColor: this.getColorGradient(compositeKey, colorKeyPrefix, 'area'),
			lineColor: lineColor,
			pointPlacement: 'on',
			marker: {
				enabled: false,
			},
			id: compositeKey,
			fuel: fuelKey,
			fuelName:
				this.translator.instant('common.' + fuelKey) +
				(isForecast
					? ' (' +
					  this.translator.instant('common.forecast').toLowerCase() +
					  ')'
					: ''),
			dataStreamName:
				this.translator.instant('common.' + dataStream) +
				(isForecast
					? ' (' +
					  this.translator.instant('common.forecast').toLowerCase() +
					  ')'
					: ''),
			customOptions: {
				isForecast: isForecast,
			},
			zIndex: 4,
		}

		return [serie]
	}

	/**
	 * Sorts series based on 1) forecast or not, and 2) their fuel.
	 * Forecast always goes on the top.
	 * Elec at the bottom, then district heating on top of that, then gas all the way at the top.
	 * @param series The list of series to sort
	 */
	private sortSeries(series: any[]): any[] {
		return series.sort(this.compareSeries).reverse()
	}

	private severalFuelsInSeriesList(series: any[]): boolean {
		// look at the id in each series, these correspond to the fuel type elec, district_heating, gas
		const foundIds: any[] = []
		series.forEach((serie) => {
			if (!foundIds.includes(serie.id)) foundIds.push(serie.id)
		})

		return foundIds.length > 1
	}

	/**
	 * Takes in two series with id fields, where the id field is one of thepossible fuel enums.
	 * @param a a series with an id field
	 * @param b a series with an id field
	 * If a is smaller than b, returns -1. If a === b, returns 0. If a > b, returns 1.
	 * Elec = 1, Heat = 2, Gas = 3.
	 * @returns a number
	 */
	private compareSeries(a: any, b: any): number {
		if (a.stack != b.stack) return stackSorter(a.stack, b.stack)
		return fuelSorter(a.fuel, b.fuel)
	}

	applyRoundingToSeries(series: any[], roundEverything = false): any[] {
		if (series.length === 0) return series

		// make sure all series passed have the same amount of data!!!
		const referenceDataLength = series[0].data.length
		if (!series.every((s) => s.data.length === referenceDataLength))
			return series

		const stackMap = new Map<string, any[]>()

		series.forEach((serie) => {
			if (stackMap.has(serie.stack)) {
				// add to the list
				;(stackMap.get(serie.stack) ?? []).push(serie)
			} else {
				stackMap.set(serie.stack, [serie])
			}
		})

		if (roundEverything) {
			series.forEach((serie) => {
				serie.borderRadiusTopLeft = 2
				serie.borderRadiusTopRight = 2
			})
		} else {
			stackMap.forEach((seriesList, stackKey) => {
				// seriesList is a list of series that are stacked on top of each other.
				// seriesList[0] seems to always be the forecast
				// seriesList[1] seems to always be the consumption
				// There doesn't seem to be a easy way to set border-radius per data-point, so we have to set it for the whole series
				// We don't want the consumption to be rounded when there is forecast stacked on top of it.
				// Since we have a default rounding on the consumption, it's enough if we just round the forecast.
				// The default rounding automatically doesn't round when it's stacked columns on top of it.
				const forecastSeries = seriesList.find(
					(s) => s.customOptions.isForecast,
				)
				if (forecastSeries) {
					forecastSeries.borderRadiusTopLeft = 2
					forecastSeries.borderRadiusTopRight = 2
				}
			})
		}

		// get the stuff back out of the map and into a returnable list
		let completedSeries: any[] = []
		stackMap.forEach(
			(seriesList) => (completedSeries = completedSeries.concat(seriesList)),
		)

		return completedSeries
	}

	public getAvgPlotline(
		data: number[],
		unit: string,
		fontFamily: string,
		chartType: string,
		dashStyle = 'Solid', // Can also be 'Dash' and more.
		dashColor = this.themeService.getPropVal('avg-plot-line', 'text-primary'),
	): any[] {
		const zIndex = chartType === 'column' ? 3 : 5
		let nNulls = 0
		data.forEach((d) => {
			if (d === null) nNulls++
		})
		const avg = data.reduce((a, b) => a + b, 0) / (data.length - nNulls)
		return [
			{
				color: dashColor,
				value: avg,
				width: 1,
				dashStyle: dashStyle,
				zIndex: zIndex,
				label: {
					useHTML: true,
					formatter: () => {
						return (
							`<div style="background-color: var(--eliq-card-background, #fff); font-family: ${fontFamily}; padding: 6px; border-radius: var(--eliq-card-border-radius); box-shadow: 0 4px 1rem 0 rgba(0,0,0,0.1); opacity: 0.9; border: 1px solid #e3e3e3">` +
							'<p style="margin: 0px; font-weight: 600;">' +
							this.translator.instant('common.average') +
							': ' +
							this.formatNumber(avg, unit) +
							'</p>' +
							'</div>'
						)
					},
					x: 4,
					y: -5,
				},
			},
		]
	}

	private formatNumber(value: any, unit: any) {
		return this.eliqNumberPipe.transform(value, unit)
		//return unit === "cost" ? this.currencyPipe.transform(value) : this.decPipe.transform(value) + " kWh";
	}

	public getTemperatureSeries(temperature: number[], period: Period) {
		const date = new Date()
		let currentVal = 0
		if (period.getLastDate() > date) {
			if (period.getPeriodType() === 'day') {
				currentVal = date.getHours() - 1
			} else if (period.getPeriodType() === 'year') {
				// i dont think this one ever runs
				currentVal = date.getMonth()
			} else if (period.getPeriodType() === 'month') {
				currentVal = date.getDate() - 1
			} else if (period.getPeriodType() === 'week') {
				currentVal = date.getDay()
				// getDay returns sunday = 0, so yeah i dont like this
				if (currentVal === 0) currentVal = 6
				else currentVal -= 1
			}
		} else {
			currentVal = temperature.length
		}

		return [
			{
				type: 'spline',
				data: temperature,
				yAxis: 1,
				animation: {
					duration: 1000,
				},
				color: this.themeService.getPropVal('temp-color').trim(),
				lineWidth: 3,
				zIndex: 20,
				marker: {
					enabled: false,
				},
				enableMouseTracking: false,
				visible: true,
				id: 'temperature',
				name: this.translator.instant('common.outside_temp'),
				suffix: '&deg;C',
				zoneAxis: 'x',
				zones: [
					{
						value: currentVal,
					},
					{
						dashStyle: 'dash',
					},
				],
			},
		]
	}

	public getCategories(
		period: Period,
		resolution?: ResolutionType,
		categoryType = 'standard',
	) {
		if (period.getPeriodType() === PeriodType.THIRTEENMONTH) {
			return this.dateTranslator.getAllTranslatedForNMonths(period, true)
		} else if (period.getPeriodType() === PeriodType.Year) {
			if (categoryType == 'numeric')
				return [
					'01',
					'02',
					'03',
					'04',
					'05',
					'06',
					'07',
					'08',
					'09',
					'10',
					'11',
					'12',
				]
			return this.dateTranslator
				.getMonths('short', true)
				.map((month: string) => this.capitalize(month))
		} else if (period.getPeriodType() === PeriodType.Month) {
			return period.getDates().map((date: Date) => {
				return date.getDate()
			})
		} else if (period.getPeriodType() === PeriodType.Week) {
			return period.getDates().map((date: Date) => {
				return this.capitalize(
					this.dateTranslator.getTranslatedWeekday(date, 'long'),
				)
			})
		} else if (period.getPeriodType() === PeriodType.Day) {
			const dates = resolution
				? period.getDatesForResolution(resolution)
				: period.getDates()
			const retVal = dates.reduce((acc: any, curDate: any, i: number) => {
				// "slice(-2) only keeps 2 last characters, so 1 becomes 01 and both are kept but 12 becomes 012 and 12 is kept"
				const hours = ('0' + curDate.getHours()).slice(-2)
				const minutes = ('0' + curDate.getMinutes()).slice(-2)
				acc.push(hours + ':' + minutes)
				return acc
			}, [])
			return retVal
		} else {
			return period.getDates()
		}
	}

	private getColorGradient(key: string, prefix: string, chartType: string) {
		if (this.getColor(`${key}-2`, prefix, chartType)) {
			return {
				linearGradient: {
					x1: 0,
					x2: 0,
					y1: 0,
					y2: 1,
				},
				stops: [
					[0, this.getColor(`${key}`, prefix, chartType)],
					[1, this.getColor(`${key}-2`, prefix, chartType)],
				],
			}
		} else {
			return this.getColor(key, prefix, chartType)
		}
	}

	private getColor(key: string, prefix: string, chartType: string) {
		const str = `--${prefix}-${chartType}-${key}-color`
		return this.themeService.getPropVal(str.replace('--eliq-', ''))
	}

	public getDateString(
		date: Date,
		periodType: PeriodType,
		resolution: ResolutionType,
	): string {
		if (typeof date === 'undefined') {
			return ''
		}

		if (periodType === PeriodType.Day) {
			let date2: Date = new Date()
			if (resolution === ResolutionType.ThirtyMin) {
				date2 = addMinutes(date, 30)
			} else if (resolution === ResolutionType.Hour) {
				date2 = addHours(date, 1)
			}

			const part1 = this.datePipe.transform(date, 'time')
			const part2 = this.datePipe.transform(date2, 'time')
			return part1 + ' - ' + part2
		} else if (
			periodType === PeriodType.Week ||
			periodType === PeriodType.Month
		) {
			return this.dateTranslator.getDateTimeString(date, {
				weekday: 'long',
				day: 'numeric',
				month: 'short',
			})
		} else if (periodType === PeriodType.Year) {
			//return this.datePipe.transform(date, { month: 'long', year: 'numeric' })
			return this.datePipe.transform(date, { month: 'long', year: 'numeric' })
		} else if (periodType === PeriodType.THIRTEENMONTH) {
			//return this.datePipe.transform(date, { month: 'long', year: 'numeric' })
			return this.datePipe.transform(date, { month: 'long', year: 'numeric' })
		}

		return ''
	}

	private capitalize(s: string): string {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}
}
