import { formatISO } from 'date-fns'
import {
	BehaviorSubject,
	forkJoin,
	Observable,
	of,
	ReplaySubject,
	Subject,
	Subscription,
	throwError,
	timer,
} from 'rxjs'
import {
	catchError,
	delay,
	map,
	mergeMap,
	retry,
	switchMap,
	take,
	takeUntil,
	takeWhile,
	tap,
} from 'rxjs/operators'

import { Injectable, makeStateKey, TransferState } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
	CacheService,
	EliqApiHttpClient,
	EnvironmentService,
} from '@eliq/data-access'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CookieService } from '@eliq/data-access/cookie'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslateService } from '@ngx-translate/core'
import { WaitForDataService } from './wait-for-data.service'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ModalService } from '@eliq/ui/modal'
import {
	APIFuel,
	APILocation,
	APIUser,
	DataStreamType,
	Fuel,
	FuelType,
	getResolutionTypeFromLogFreq,
	LocationHttpService,
	ResolutionType,
	UnitType,
	UserHttpService,
} from '../..'
import { CurrentPeriodService } from '../current-period/current-period.service'
import { Location } from '../..'
// This Class is a data store wich hold the webs core data.
// The data consists of general inforation about the logged in user, such as user id, location ids and account ids.
// This class should not be used as a data store for content shown in app, just data that is needed to know how to query the api
const CACHE_SIZE = 1

/*
	import: APIDataStream
	export: APIDataStream
	consumption: APIDataStream
	production: APIDataStream
	resolution: ResolutionType
	type: FuelType
*/

const SKELETAL_LOCATION: Location = {
	id: 0,
	name: 'Loading...',
	fuels: [],
	pvSystems: [],
	address: { city: '', country_code: '', street_address: '', postal_code: '' },
}

const SKELETAL_USER: APIUser = {
	id: 0,
	ext_ref: '',
	forname: '',
	surname: '',
	name: '',
	phone: '',
	email: '',
	language_code: '',
	created_date: '',
	terms_accepted: '',
}

//Errors

enum CoreDS_ErrorSubType {
	NETWORK = 'NETWORK',
	UNKNOWN = 'UNKNOWN',
}

class CoreDS_Error extends Error {
	subtype: CoreDS_ErrorSubType = CoreDS_ErrorSubType.UNKNOWN
	causes: Error[] = []
	constructor(
		cookieService: CookieService,
		modalService: ModalService,
		message,
		cause?: Error,
		subtype?: CoreDS_ErrorSubType,
	) {
		super(message, { cause })
		let err: Error = this as Error
		while (err.cause) {
			err = err.cause as Error
			if (err['status'] && err['url']) {
				this.subtype = CoreDS_ErrorSubType.NETWORK
				this.causes.push({ url: err['url'], status: err['status'] } as any)
			}
			this.causes.push(err)
		}
		this.subtype = subtype ?? this.subtype
		this.name = this.constructor.name + '_' + this.subtype

		if (cookieService.get('accessToken')?.length) {
			modalService.openErrorModal({
				debugData:
					this.message +
					this.causes.map((c) => '\n -> ' + JSON.stringify(c, null, 2)),
				error: this,
			})
		}
	}
}

CoreDS_Error.prototype['inspect'] = function () {
	return { causes: this.causes, stack: this.stack }
}

@Injectable({
	providedIn: 'root',
})
export class CoreDataStoreService {
	public CRITICAL_ERROR = false

	public LOCATION_ID = makeStateKey<string>('locationId')

	private loginWaiter: Subscription
	private userSubject: ReplaySubject<APIUser> = new ReplaySubject(CACHE_SIZE)
	private userCache$: Observable<APIUser> = this.userSubject.asObservable()
	private currentLocations: Location[] // makes things easier
	private locationsCacheSubject = new ReplaySubject(1) as ReplaySubject<
		Location[]
	>
	// we use this variable to keep track of if someone has made the requests we need or not.
	private locationsRequestMade = false

	private userId: number | null

	// this should be stored in local cache / session storage instead, but use this for now
	private activeLocationId: number | null = null

	private activeLocationSubject!: Subject<Location | undefined>

	constructor(
		private cookieService: CookieService,
		private env: EnvironmentService,
		private dataWaiting: WaitForDataService,
		private route: ActivatedRoute,
		private userService: UserHttpService,
		private locationService: LocationHttpService,
		private currPerService: CurrentPeriodService,
		private transferState: TransferState,
		private cacheService: CacheService,
		private httpService: EliqApiHttpClient,
		private modalService: ModalService,
		private translate: TranslateService,
	) {
		this.activeLocationSubject = new ReplaySubject(CACHE_SIZE) // caching, only one call
	}

	// Maybe consider changeing this to an observable subject instead. User props may change.
	get user(): Observable<APIUser> {
		if (!this.userId) {
			const userIdStored = this.cookieService.get('userId')
			this.userId = userIdStored ? parseInt(userIdStored) : null
			if (this.userId !== null) {
				this.setUserByUserId(+this.userId).subscribe()
			}
		}

		return this.userCache$
	}

	/**
	 * Gets the active location and checks all fuels matching the fuelType if they have data for the dataStreams.
	 * If any of the fuels have data from the source 'load_curve_estimation' or 'pv_disagg' the function will return true.
	 *
	 * @public
	 * @param {FuelType} fuelType
	 * @param {{}} [dataStreams=['consumption']]
	 * @returns {Observable<boolean>}
	 */
	public fuelIsEstimated(
		fuelType: FuelType,
		dataStreams: DataStreamType[] = ['consumption'],
	): Observable<boolean> {
		return this.getActiveLocation().pipe(
			take(1),
			map((loc) => {
				if (!loc) {
					throw new Error('No active location')
				}
				const fuel: Fuel | undefined = loc.fuels.find(
					(f) => f.type === fuelType,
				)
				if (!fuel) {
					throw new Error(`Fuel \`${fuel}\` not found in location ${loc.id}`)
				}
				for (const ds of dataStreams) {
					const fuelSrc = fuel?.[ds]?.['source']

					if (
						fuelSrc &&
						['load_curve_estimation', 'pv_disagg'].includes(fuelSrc)
					) {
						return true
					}
				}
				return false
			}),
		)
	}

	public hasSpecificData(
		fuel: FuelType = FuelType.ELEC,
		datastream: Readonly<DataStreamType>,
		unit: UnitType,
		resolution = ResolutionType.Month,
		cache = false,
	): Observable<boolean> {
		return this.getActiveLocation(cache).pipe(
			take(1),
			switchMap((loc) => {
				if (!loc) {
					return throwError(() => new Error('No active location'))
				}
				return this.dataWaiting.locationHasSpecificData(
					loc.id,
					fuel,
					datastream,
					unit,
					resolution,
					cache,
				)
			}),
		)
	}
	public waitForSpecificData(
		fuelType = FuelType.ELEC,
		dataStream: DataStreamType,
		unit: UnitType = 'energy',
		resolution = ResolutionType.Month,
		retryCount = 10,
		retryDelayMs = 2000,
	): Observable<boolean> {
		return this.getActiveLocation(true).pipe(
			switchMap((loc) => {
				if (!loc) {
					this.cacheService.clear()
					return throwError(() => new Error('No active location'))
				}
				return this.dataWaiting
					.locationHasSpecificData(
						loc.id,
						fuelType,
						dataStream,
						unit,
						resolution,
						false,
					)
					.pipe(take(1))
			}),
			switchMap((hasData) => {
				if (!hasData) {
					this.cacheService.clear()
					return throwError(
						() =>
							new Error(
								`No data could be found for ${fuelType} ${dataStream} ${unit} ${resolution}`,
							),
					)
				}
				return of(true)
			}),
			retry({
				count: retryCount,
				delay: retryDelayMs,
			}),
		)
	}

	public waitForConsumption(): Observable<boolean> {
		return this.waitForSpecificData(
			FuelType.ELEC,
			'consumption',
			'energy',
			ResolutionType.Month,
			10,
			2000,
		)
	}

	/**
	 * Instantiates the user object in core data store. Useful for when doing non-standard logins,
	 * for example templogin.
	 * @param userId the user id we got
	 * @returns a boolean indicating if the operation was successful or not.
	 */
	public setUserByUserId(userId: number | null): Observable<boolean> {
		return this.userService.getUser(userId).pipe(
			take(1),
			tap((user) => this.setUser(user)),
			map(() => true),
			catchError((err, caught) =>
				this.cookieService.get('accessToken')?.length
					? throwError(() => {
							console.error(
								'Could not set user by user id (coreDS) - User is logged out unless the page is embedded in the app.',
							)
							if (
								this.cookieService.get('refreshToken')?.length ||
								location.href.includes('?hide=header:1') ||
								location.href.includes('?hide=header:true') ||
								(localStorage.getItem('embeddedParams') || '').includes('true')
							) {
								this.CRITICAL_ERROR = true
								return new CoreDS_Error(
									this.cookieService,
									this.modalService,
									'It seems you are unexpectedly logged out. Please log in again.',
									err,
								)
							} else {
								this.CRITICAL_ERROR = false
								location.href = '/login'
								this.cookieService.remove('accessToken')
								this.cookieService.remove('refreshToken')
								return new Error('You got logged out')
							}
					  })
					: of(false),
			),
		)
	}

	public setUser(user: APIUser) {
		this.userId = user.id
		this.userSubject.next(user)
	}

	public refetchLocations(): Observable<Location[]> {
		this.locationsRequestMade = false
		this.cacheService.clear()
		return this.locations
	}

	// No need for observable subject, assume the location will be the same for session (loc properties should not be fetched here)
	get locations(): Observable<Location[]> {
		if (!this.locationsRequestMade) {
			this.locationsRequestMade = true

			const userId = this.cookieService.get('userId')
			if (userId === null) {
				// Here is a pretty special case, so listen up. At this point, someone wants locations, but the user isn't logged in yet.
				// This can happen. There is no guarantee that someone else will call this function after the user logs in, meaning that we can end
				// up with starvation. So we need to create a worker once we end up here, that checks the status of local storage every n seconds
				// and tries to get locations with it.
				this.waitForUserToLogin()
				return this.locationsCacheSubject
			}

			this.getUserLocations(+(userId ?? 0), false)
				.pipe(
					retry({
						count: 5,
						delay: 1000,
					}),
					switchMap((apiLocations) =>
						forkJoin(
							apiLocations.map((apiLoc) =>
								this.apiLocationToCompleteLocation(apiLoc),
							),
						),
					),
				)
				.subscribe({
					next: (locations) => {
						if (
							!locations ||
							!locations.some((loc) => typeof loc !== undefined)
						) {
							this.currentLocations = []
						} else {
							this.currentLocations = locations as Location[]
							this.locationsCacheSubject.next(locations as Location[])
							// this.locationsCacheSubject.complete() // <--
						}
					},
					error: (err) => {
						this.locationsCacheSubject.error(err)
					},
				})
		}

		return this.locationsCacheSubject.asObservable()
	}

	private lastError = 0

	public getUserLocations(
		userId: number | null,
		cached = true,
	): Observable<APILocation[]> {
		if (Date.now() - this.lastError < 1000 * 60) {
			return of([])
		}
		if (!userId) {
			return of([])
		}
		return this.httpService
			.get<APILocation[]>(
				`/v3/users/${userId}/locations${
					!cached ? '?nocache=' + Date.now() : ''
				}`,
			)
			.pipe(
				catchError((err, c) => {
					console.error(err, 'caught:', c)
					return this.locations as unknown as Observable<APILocation[]>
				}),
				tap((locations) => {
					if (Array.isArray(locations) && locations.length === 0) {
						this.modalService.openErrorModal({
							message: this.translate.instant('common.general_error'),
							debugData: JSON.stringify(
								{
									description:
										'Locations returned successfully but was an empty array: ' +
										JSON.stringify(locations),
								},
								null,
								2,
							),
						})
					}
				}),
			)
	}

	public getLoggedInUserLocations(enableCache = true) {
		return this.getUserLocations(
			parseInt(this.cookieService.get('userId') ?? '0') ?? 0,
			enableCache,
		)
	}

	private waitForUserToLogin() {
		if (!this.loginWaiter) {
			this.loginWaiter = timer(0, 2000).subscribe((_) => {
				const user = this.cookieService.get('userId')
				if (user !== null) {
					this.locations.subscribe({
						next: () => console.warn('waitForUserToLogin:'),
						error: () => console.error('waitForuserToLogin error'),
					})
					this.loginWaiter?.unsubscribe()
				}
			})
		}
	}

	public getActiveLocation(cached = true): Observable<Location | undefined> {
		return (cached ? this.locations : this.refetchLocations()).pipe(
			map((res) => {
				if (this.activeLocationId == null) {
					const queryLocationIDString =
						this.route.snapshot.queryParams['location']
					const queryLocationID: number | null = !isNaN(
						parseInt(queryLocationIDString),
					)
						? parseInt(queryLocationIDString)
						: null

					if (queryLocationID) {
						const queryLoc = res.find((v) => v.id === queryLocationID)
						return queryLoc ?? res[0]
					} else if (this.transferState.get(this.LOCATION_ID, null)) {
						const localStorageLocationID = parseInt(
							this.transferState.get(this.LOCATION_ID, '-1'),
						)
						return res.find((v) => v.id === localStorageLocationID)
					}

					return res.find((some) => some.fuels?.length > 0) ?? res[0]
				}
				const loc = res.find((x) => x.id == this.activeLocationId)

				if (loc !== null) {
					return loc
				} else {
					return res[0]
				}
			}),
			map((loc) => {
				this.activeLocationSubject.next(loc)
				return loc
			}),
		)
	}

	public setActiveLocation(activeLocationId: number): void {
		if (typeof activeLocationId !== 'number') {
			console.error(
				'setActiveLocation was called with invalid non-number value.',
			)
			return
		}

		if (this.currentLocations) {
			const loc = this.currentLocations.find((x) => x.id == activeLocationId)
			if (!((loc?.fuels?.length ?? 0) > 0)) {
				console.error(
					'setActiveLocation with id: ',
					activeLocationId,
					' did not return a location with fuels',
				)
				return
			}
			this.activeLocationSubject.next(loc)
		} else {
			console.error(
				'setActiveLocation called before locations were fetched. This is not allowed.',
			)
		}

		this.transferState.set(this.LOCATION_ID, activeLocationId.toString())
		this.activeLocationId = activeLocationId
		this.refetchLocations().pipe(
			map((res: Location[]) => {
				const loc = res.find((x) => x.id == activeLocationId)
				if (!((loc?.fuels?.length ?? 0) > 0)) {
					console.error(
						'setActiveLocation with id: ',
						activeLocationId,
						' did not return a location with fuels',
					)
					return
				}
				this.activeLocationSubject.next(loc)
			}),
			catchError((err) => {
				return of(undefined)
			}),
		)
	}

	public changeLocationName(locId: number, newName: string) {
		const newLocations: Location[] = []
		this.currentLocations.forEach((loc) => {
			if ((loc?.id as number) === locId) {
				newLocations.push(
					new Location(
						loc?.id as number,
						newName,
						loc.fuels,
						loc.pvSystems,
						loc.address,
					),
				)
			} else {
				newLocations.push(loc)
			}
		})
		this.currentLocations = newLocations
		this.locationsCacheSubject.next(newLocations)
	}

	public apiLocationToCompleteLocation(
		loc: APILocation,
	): Observable<Location | undefined> {
		if (loc.fuels) {
			const fuels = Object.keys(loc.fuels)
				.map((fuelKey) => {
					return { fuel: loc.fuels[fuelKey] as APIFuel, key: fuelKey }
				})
				.map(({ fuel, key }) => new Fuel(key as FuelType, fuel))

			return of(
				new Location(
					loc?.id as number,
					loc.name,
					fuels,
					loc.pv_systems,
					loc.address,
				),
			)
		} else {
			// we have the old api standard with devices and such
			return this.getFuelsFromDevices(loc?.id as number).pipe(
				map(
					(fuel) =>
						new Location(
							loc?.id as number,
							loc.name,
							fuel,
							loc.pv_systems,
							loc.address,
						),
				),
			)
		}
	}

	private getFuelsFromDevices(locId: number): Observable<Fuel[]> {
		return this.locationService.getLocationDevices(locId).pipe(
			mergeMap((devices) =>
				forkJoin(
					devices.map((device) => {
						const resolution = getResolutionTypeFromLogFreq(device.freq_log)
						return this.currPerService
							.getLastMonthWithData(locId, device.fuel, resolution, 12, true)
							.pipe(
								map((response) => ({
									latestDate: response,
									fuelType: device.fuel,
								})),
								map((res) => {
									return new Fuel(res.fuelType as FuelType, {
										consumption: {
											type: 'consumption',
											data_from: undefined as unknown as string,
											data_to: formatISO(
												res.latestDate?.day
													? res.latestDate.day
													: res.latestDate.month,
											),
											resolution: resolution,
											units: ['cost', 'energy'],
										},
									})
								}),
							)
					}),
				),
			),
		)
	}
}
